import {
  UPDATE_BROKER,
  UPDATE_PUNI_BROKER,
  UPDATE_RETAIL_BROKER,
  UPDATE_SURPLUS_BROKER,
  UPDATE_PREFERRED_UNDERWRITER,
  UPDATE_PREFERRED_UNDERWRITER_NAME,
  UPDATE_SECONDARY_UNDERWRITER,
} from "./peopleActionTypes";

export const updateBroker = (broker) => ({
  type: UPDATE_BROKER,
  payload: broker,
});

export const updatePuniBroker = (broker) => ({
  type: UPDATE_PUNI_BROKER,
  payload: broker,
});

export const updateRetailBroker = (broker) => ({
  type: UPDATE_RETAIL_BROKER,
  payload: broker,
});

export const updateSurplusBroker = (broker) => ({
  type: UPDATE_SURPLUS_BROKER,
  payload: broker,
});

export const updatePreferredUnderwriter = (underwriter) => ({
  type: UPDATE_PREFERRED_UNDERWRITER,
  payload: underwriter,
});

export const updatePreferredUnderwriterName = (underwriterName) => ({
  type: UPDATE_PREFERRED_UNDERWRITER_NAME,
  payload: underwriterName,
});

export const updateSecondaryUnderwriter = (underwriter) => {
  return {
    type: UPDATE_SECONDARY_UNDERWRITER,
    payload: underwriter,
  };
};
