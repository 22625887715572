import { useLocalStorageJsonState } from "./stateHooks";
import { useIsVisible } from "./staticDataHooks";
import { useState } from "react";

export const useIsFullWidth = () => {
  const nonPersistedState = useState(false);
  const persistedState = useLocalStorageJsonState("isFullWidth", {
    default: false,
  });

  const { isVisible: isPersistentFullWidth } = useIsVisible(
    "persistentFullWidth"
  );

  const [isFullWidth, setIsFullWidth] = isPersistentFullWidth
    ? persistedState
    : nonPersistedState;

  const toggleIsFullWidth = () => {
    setIsFullWidth(!isFullWidth);
  };

  return {
    isFullWidth,
    setIsFullWidth,
    toggleIsFullWidth,
  };
};

export const useHasStickyHeaders = () => {
  const [
    hasStickyHeaders,
    setHasStickyHeaders,
  ] = useLocalStorageJsonState("hasStickyHeaders", { default: false });

  const toggleHasStickyHeaders = () => setHasStickyHeaders(!hasStickyHeaders);

  return {
    hasStickyHeaders,
    setHasStickyHeaders,
    toggleHasStickyHeaders,
  };
};
