import IconButton from "../IconButton";
import {
  NavigateBeforeRounded as PreviousPageIcon,
  NavigateNextRounded as NextPageIcon,
  FirstPageRounded as FirstPageIcon,
  LastPageRounded as LastPageIcon,
} from "@mui/icons-material";
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

const PaginationControls = ({
  rowCount,
  page,
  setPage,
  pageSize,
  setPageSize,
  pageSizeOptions,
  pageCount,
  sx,
}) => {
  const enDashChar = "\u2013";
  const start = pageSize * (page - 1) + 1;
  const end = page === pageCount ? rowCount : pageSize * page;

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"flex-end"}
      spacing={3}
      sx={sx}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant={"body2"}>{"Rows per page: "}</Typography>
        <FormControl size={"small"}>
          <Select
            sx={{ padding: 0, marginLeft: 1 }}
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {pageSizeOptions.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Typography variant={"body2"}>
        {`${start}${enDashChar}${end} of ${rowCount}`}
      </Typography>
      <Stack direction={"row"} alignItems={"center"}>
        <IconButton
          tooltip={page === 1 ? null : "First Page"}
          icon={FirstPageIcon}
          scale={"small"}
          disabled={page === 1}
          onClick={() => setPage(1)}
        />
        <IconButton
          tooltip={page <= 1 ? null : "Previous Page"}
          icon={PreviousPageIcon}
          scale={"small"}
          disabled={page <= 1}
          onClick={() => setPage(Math.max(0, page - 1))}
        />
        <IconButton
          tooltip={page >= pageCount ? null : "Next Page"}
          icon={NextPageIcon}
          scale={"small"}
          disabled={page >= pageCount}
          onClick={() => setPage(Math.min(pageCount, page + 1))}
        />
        <IconButton
          tooltip={page === pageCount ? null : "Last Page"}
          icon={LastPageIcon}
          scale={"small"}
          disabled={page === pageCount}
          onClick={() => setPage(pageCount)}
        />
      </Stack>
    </Stack>
  );
};

export default PaginationControls;
