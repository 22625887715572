import BaseLayerBurn from "./baseLayerBurn/BaseLayerBurn";
import Modeling from "./modelData/Modeling";
import * as navigationSupport from "components/common/Navigation.support";
import DashboardContent from "components/layout/DashboardContent";
import DashboardHeader from "components/layout/DashboardHeader";
import BurningCost from "components/pricing/burningCost/BurningCost";
import ClaimsSelector from "components/pricing/claimsSelector/ClaimsSelector";
import DnfLayerPricing from "components/pricing/dnfLayerPricing/DnfLayerPricing";
import ExpiringProgram from "components/pricing/expiringProgram/expiringProgram";
import LayerPricing from "components/pricing/layerPricing/LayerPricing";
import LossFrequency from "components/pricing/lossFrequency/LossFrequency";
import RateChange from "components/pricing/rateChange/RateChange";
import TailAnalysis from "components/pricing/tailAnalysis/TailAnalysis";
import TowerPricing from "components/pricing/towerPricing/TowerPricing";
import Ultimates from "components/pricing/ultimates/Ultimates";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const Pricing = (props) => {
  const {
    orderSubTabs,
    isSubTabOrderLoading,
  } = navigationSupport.useOrderSubTabs({ tabName: "Analysis" });

  if (isSubTabOrderLoading) {
    return <></>;
  }

  const tabs = [
    {
      title: "Tail Analysis",
      path: "tail_analysis",
      element: <TailAnalysis />,
      visibilityTag: "pricing.tailAnalysis",
      defaultVisibility: false,
    },
    {
      title: "Claims",
      path: "claims",
      element: <ClaimsSelector />,
      visibilityTag: "pricing.claimsSelector",
      defaultVisibility: false,
    },
    {
      title: "Loss Frequency",
      path: "loss_frequency",
      element: <LossFrequency />,
      visibilityTag: "pricing.lossFrequency",
      defaultVisibility: true,
    },
    {
      title: "Ultimate",
      path: "ultimate",
      element: <Ultimates />,
      visibilityTag: "pricing.ultimate",
      defaultVisibility: false,
    },
    {
      title: "Burning Cost",
      path: "burning_cost",
      element: <BurningCost />,
      visibilityTag: "pricing.burningCost",
      defaultVisibility: false,
    },
    {
      title: "Modeling",
      path: "modeling",
      element: <Modeling />,
      visibilityTag: "pricing.modeling",
      defaultVisibility: false,
    },
    {
      title: "Base Layer Burn",
      path: "base_layer_burn",
      element: <BaseLayerBurn />,
      visibilityTag: "pricing.baseLayerBurn",
      defaultVisibility: false,
    },
    {
      title: "Layer Pricing",
      path: "layer_pricing",
      element: <LayerPricing />,
      visibilityTag: "pricing.layerPricing",
      defaultVisibility: true,
    },
    {
      title: "D&F Layer Pricing",
      path: "dnf_layer_pricing",
      element: <DnfLayerPricing />,
      visibilityTag: "pricing.dnfLayerPricing",
      defaultVisibility: false,
    },
    {
      title: "Tower Pricing",
      path: "tower_pricing",
      element: <TowerPricing />,
      visibilityTag: "pricing.towerPricing",
      defaultVisibility: true,
    },
    {
      title: "Expiring Program",
      path: "expiring_program",
      element: <ExpiringProgram />,
      visibilityTag: "pricing.expiringProgram",
      defaultVisibility: true,
    },
    {
      title: "Rate Change",
      path: "rate_change",
      element: <RateChange />,
      hidden: props.hasSubmission && !props.hasPriorSubmission,
      visibilityTag: "pricing.rateChange",
      defaultVisibility: true,
    },
  ]
    .filter((x) => !x.hidden)
    .filter(
      (x) => props.componentVisibility[x.visibilityTag] ?? x.defaultVisibility
    );

  const orderedTabs = orderSubTabs(tabs);

  return (
    <>
      <DashboardHeader
        tabs={orderedTabs}
        parentPath={"pricing"}
        navigationState={props.navigationState}
        setNavigationState={props.setNavigationState}
        isFullWidth={props.isFullWidth}
        sticky={props.hasStickyHeaders}
      />
      <DashboardContent isFullWidth={props.isFullWidth}>
        <Routes>
          {orderedTabs.map(({ element, path }) => (
            <Route key={path} path={`${path}/*`} element={element} />
          ))}
          <Route
            path={"*"}
            element={
              <Navigate
                to={
                  props.navigationState?.pricing != null &&
                  orderedTabs.some(
                    (tab) => tab.path === props.navigationState.pricing
                  )
                    ? props.navigationState.pricing
                    : orderedTabs[0].path
                }
                replace={true}
              />
            }
          />
        </Routes>
      </DashboardContent>
    </>
  );
};

export const mapStateToProps = (state) => ({
  componentVisibility:
    userSelectors.selectUserConfig(state).componentVisibility || {},
  hasSubmission: persistenceSelectors.selectFilename(state) != null,
  hasPriorSubmission: programSelectors.getPriorSubmission(state) != null,
});

export default connect(mapStateToProps)(Pricing);
