import ActiveSubmission from "./layout/ActiveSubmission";
import Navigation from "./layout/Navigation";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "components/common/Button";
import DefaultLoader from "components/common/DefaultLoader";
import ErrorDialog from "components/error/ErrorDialog";
import Inputs from "components/inputs/Inputs";
import Dashboard from "components/layout/Dashboard";
import Footer from "components/layout/Footer";
import Peers from "components/peers/Peers";
import Pricing from "components/pricing/Pricing";
import Summary from "components/summary/Summary";
import { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useRetrieveNavigationConfigQuery } from "services/staticDataService";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import { useIsFullWidth, useHasStickyHeaders } from "store/hooks/layoutHooks";
import * as submissionHooks from "store/hooks/submissionHooks";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

const SubmissionErrorDialog = ({ open, onSearch, message }) => (
  <Dialog open={open} maxWidth={"md"} fullWidth>
    <DialogTitle>{"Could Not Load Submission"}</DialogTitle>
    <DialogContent>
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onSearch} color={"primary"} startIcon={<SearchIcon />}>
        {"Search Submissions"}
      </Button>
    </DialogActions>
  </Dialog>
);

const RenewalErrorDialog = ({
  open,
  onCancel,
  onProceed,
  message,
  isDisabled,
}) => (
  <Dialog open={open} onClose={onCancel} maxWidth={"md"} fullWidth>
    <DialogTitle>{"Are you sure you mean to renew?"}</DialogTitle>
    <DialogContent>{message}</DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color={"secondary"}>
        {"Cancel"}
      </Button>
      <Button isDisabled={isDisabled} onClick={onProceed} color={"primary"}>
        {"Renew"}
      </Button>
    </DialogActions>
  </Dialog>
);

const Submission = ({
  source,
  renewErrorIsActive,
  renewErrorMessage,
  renewSubmission,
  cancelSubmission,
  filename,
}) => {
  const [navigationState, setNavigationState] = useState({});

  const { submissionId: id, versionId } = useParams();

  const submissionStatus = useSelector(
    persistenceSelectors.selectSubmissionStatus
  );

  const { isLoading, error } = submissionHooks.useLoadSubmission(id, {
    source,
    isLoadable: id !== "new",
    versionId,
  });

  submissionHooks.useRedirectToSavedSubmission(id, {
    rootPath: "/submissions",
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (filename === null && id === "new") {
      navigate("/submissions");
    }
  }, [filename, id, navigate]);

  useEffect(() => {
    const handleOnUnload = () => {
      sessionStorage.removeItem("hasVisitedSubmission");
    };
    window.addEventListener("unload", handleOnUnload);

    return () => {
      window.removeEventListener("unload", handleOnUnload);
    };
  }, []);

  if (
    !window.sessionStorage.getItem("hasVisitedSubmission") &&
    window.location.pathname !== "/submissions"
  ) {
    window.sessionStorage.setItem("hasVisitedSubmission", true);
  }

  const { isFullWidth, toggleIsFullWidth } = useIsFullWidth();
  const { hasStickyHeaders, toggleHasStickyHeaders } = useHasStickyHeaders();

  const {
    data: navigationConfig,
    isLoading: isNavigationConfigLoading,
  } = useRetrieveNavigationConfigQuery();

  if (isNavigationConfigLoading) {
    return <></>;
  }

  const translation = {
    Inputs: "inputs",
    Analysis: "pricing",
    Comparison: "peers",
    Summary: "summary",
  };
  const defaultPath =
    navigationConfig?.tabOrder?.map(
      ([tabName, _]) => translation[tabName]
    )?.[0] ?? "inputs";

  const variant =
    {
      overlay: "readonly",
      pending: "pending",
      deleted: "warning",
      new: "pending",
      default: "default",
    }[submissionStatus] ?? "default";

  return isLoading ? (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    >
      <DefaultLoader
        color={"#dc7f4c"}
        size={100}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Box>
  ) : (
    <>
      <ErrorDialog />
      <RenewalErrorDialog
        open={renewErrorIsActive}
        message={renewErrorMessage}
        onProceed={() =>
          renewSubmission({
            navigate,
            force: true,
          })
        }
        onCancel={cancelSubmission}
      />
      <SubmissionErrorDialog
        open={!!error}
        message={error}
        onSearch={() => navigate("/submissions")}
      />
      <div className={"app"}>
        <Dashboard variant={variant}>
          <ActiveSubmission isFullWidth={isFullWidth} />
          <Navigation
            isFullWidth={isFullWidth}
            toggleIsFullWidth={toggleIsFullWidth}
            hasStickyHeaders={hasStickyHeaders}
            toggleHasStickyHeaders={toggleHasStickyHeaders}
          />
          <Routes>
            <Route
              path={"inputs/*"}
              element={
                <Inputs
                  isFullWidth={isFullWidth}
                  hasStickyHeaders={hasStickyHeaders}
                  navigationState={navigationState}
                  setNavigationState={setNavigationState}
                />
              }
            />
            <Route
              path={"pricing/*"}
              element={
                <Pricing
                  isFullWidth={isFullWidth}
                  hasStickyHeaders={hasStickyHeaders}
                  navigationState={navigationState}
                  setNavigationState={setNavigationState}
                />
              }
            />
            <Route
              path={"peers/*"}
              element={
                <Peers
                  isFullWidth={isFullWidth}
                  hasStickyHeaders={hasStickyHeaders}
                  navigationState={navigationState}
                  setNavigationState={setNavigationState}
                />
              }
            />
            <Route
              path={"summary/*"}
              element={
                <Summary
                  isFullWidth={isFullWidth}
                  hasStickyHeaders={hasStickyHeaders}
                  navigationState={navigationState}
                  setNavigationState={setNavigationState}
                />
              }
            />
            <Route
              path={"*"}
              element={<Navigate to={defaultPath} replace={true} />}
            />
          </Routes>
        </Dashboard>
        <Footer />
      </div>
    </>
  );
};

export const mapStateToProps = (state) => {
  return {
    renewErrorIsActive: state?.persistence?.renewError != null,
    renewErrorMessage: state?.persistence?.renewError,
    filename: state?.persistence?.filename,
  };
};

const mapDispatchToProps = {
  renewSubmission: persistenceActions.renewSubmission,
  cancelSubmission: persistenceActions.cancelRenewSubmission,
};

export default connect(mapStateToProps, mapDispatchToProps)(Submission);
