import { createApi } from "@reduxjs/toolkit/query/react";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.admin(args.url),
      args.payload || {}
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    listTemplates: builder.query({
      query: () => ({
        url: "templates",
      }),
    }),
    listCompanies: builder.query({
      query: () => ({
        url: "companies",
      }),
    }),
    createCompany: builder.mutation({
      query: ({ name, template, users }) => ({
        url: "companies",
        payload: {
          body: JSON.stringify({
            name,
            filesTemplateKey: template,
            users,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    getAllowedUserPermissions: builder.query({
      query: () => ({
        url: "users/retrieve-privilege-list",
        payload: {
          method: "post",
        },
      }),
    }),
    createUser: builder.mutation({
      query: ({
        email,
        enabled,
        perms,
        timeout,
        sendEmail,
        setPassword,
        password,
        companyId,
        userLogMessage,
      }) => ({
        url: "users",
        payload: {
          body: JSON.stringify({
            companyId,
            email,
            enabled,
            perms,
            timeout,
            sendEmail,
            setPassword,
            password,
            userLogMessage,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    configureUser: builder.mutation({
      query: ({
        username,
        enabled,
        perms,
        timeoutAdd,
        timeoutDelete,
        userLogMessage,
      }) => ({
        url: "users/configure",
        payload: {
          body: JSON.stringify({
            username,
            enabled,
            perms,
            timeoutAdd,
            timeoutDelete,
            userLogMessage,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    resetUserPassword: builder.mutation({
      query: ({ username, userLogMessage, companyId }) => ({
        url: "users/reset-password",
        payload: {
          body: JSON.stringify({
            username,
            userLogMessage,
            companyId,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    listUsers: builder.query({
      query: ({ companyId }) => ({
        url: `users?companyId=${companyId}`,
        payload: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    listStaticData: builder.query({
      query: ({ companyId }) => ({
        url: `static-data/${companyId}`,
      }),
    }),
    listAuditLogs: builder.query({
      query: ({ companyId, startDate, endDate, email }) => {
        const query = [];
        if (startDate) query.push(`start-date=${startDate}`);
        if (endDate) query.push(`end-date=${endDate}`);
        if (email) query.push(`email=${email}`);
        const qryTxt = query ? "?" + query.join("&") : "";
        return {
          url: `list-audit-logs/${companyId}${qryTxt}`,
        };
      },
    }),
    getStaticData: builder.query({
      query: ({ companyId, key }) => ({
        url: `static-data/${companyId}/${key}`,
      }),
    }),
    updateStaticData: builder.mutation({
      query: ({ companyId, key, data, userLogMessage }) => ({
        url: `static-data/${companyId}/${key}`,
        payload: {
          method: "post",
          body: JSON.stringify({
            data,
            userLogMessage,
          }),
        },
      }),
    }),
    adminUpdateNavigationConfig: builder.mutation({
      query: ({ companyId, config, userLogMessage }) => ({
        url: "admin-update-navigation-config",
        payload: {
          method: "post",
          body: JSON.stringify({
            companyId,
            config,
            userLogMessage,
          }),
        },
      }),
    }),
    updateUserConfig: builder.mutation({
      query: ({ companyId, userLogMessage, value }) => ({
        url: "user-config",
        payload: {
          method: "post",
          body: JSON.stringify({
            companyId,
            userLogMessage,
            value,
          }),
        },
      }),
    }),
    adminRetrieveMaintenanceStatus: builder.query({
      query: () => ({
        url: "admin-retrieve-maintenance-status",
        payload: {
          method: "post",
        },
      }),
    }),
    adminUpdateMaintenanceStatus: builder.mutation({
      query: ({ message, showWarningNow, disableNow, versionNumber }) => ({
        url: "admin-update-maintenance-status",
        payload: {
          method: "post",
          body: JSON.stringify({
            message,
            showWarningNow,
            disableNow,
            versionNumber,
          }),
        },
      }),
    }),
  }),
});

export const {
  useListTemplatesQuery,
  useListCompaniesQuery,
  useLazyListUsersQuery,
  useCreateCompanyMutation,
  useCreateUserMutation,
  useConfigureUserMutation,
  useResetUserPasswordMutation,
  useListStaticDataQuery,
  useGetStaticDataQuery,
  useLazyGetStaticDataQuery,
  useUpdateStaticDataMutation,
  useAdminUpdateNavigationConfigMutation,
  useListAuditLogsQuery,
  useUpdateUserConfigMutation,
  useAdminRetrieveMaintenanceStatusQuery,
  useAdminUpdateMaintenanceStatusMutation,
  useGetAllowedUserPermissionsQuery,
} = adminApi;
