import * as adminActions from "../../store/actions/temp/admin/adminActions";
import BigLoader from "../common/BigLoader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Grid, Typography, Checkbox, Stack, Box } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDEditor from "@uiw/react-md-editor/nohighlight";
import Component from "components/Component";
import AdminDashboard from "components/admin/AdminDashboard";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import TextField from "components/common/TextField";
import DashboardContent from "components/layout/DashboardContent";
import * as config from "config";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useCreateUserMutation,
  useConfigureUserMutation,
  useLazyListUsersQuery,
  useResetUserPasswordMutation,
  useGetAllowedUserPermissionsQuery,
} from "services/adminService";
import * as adminSelectors from "store/selectors/temp/admin/adminSelectors";
import * as yup from "yup";

const userSort = (a, b) => {
  if (a.enabled && !b.enabled) {
    return -1;
  }
  if (!a.enabled && b.enabled) {
    return 1;
  }
  return a.email.localeCompare(b.email);
};

const User = ({
  permissionsList,
  user,
  onChange,
  disabled,
  resettingPassword,
  onReset,
}) => {
  const [expireAll, setExpireAll] = useState(true);
  const [expireList, setExpireList] = useState([]);
  const [expireDate, setExpireDate] = useState(null);
  const setExpirePerm = (name, active) => {
    setExpireList((el) => {
      if (active) {
        if (!el.includes(name)) return [...el, name];
        return el;
      } else {
        if (el.includes(name)) return el.filter((e) => e !== name);
        return el;
      }
    });
  };
  const invalidDate = !/^[0-9]{4}(-[0-9]{2}){2}([ T][0-9]{2}:[0-9]{2})?$/.test(
    expireDate
  );
  const timeouts = Object.entries(
    user.timeouts ?? {}
  ).sort(([at, av], [bt, bl]) => (at > bt ? 1 : at < bt ? -1 : 0));
  const perms = user.perms ?? [];
  return (
    <Card sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Grid container alignItems={"center"} spacing={0}>
        <Grid item xs={9}>
          <Typography>{user.email}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Switch
              checked={user.enabled}
              onChange={(e) => {
                onChange({
                  user,
                  enabled: e.target.checked,
                });
              }}
              disabled={disabled}
            />
            {resettingPassword === user.email ? (
              <CircularProgress size={24} style={{ margin: 8 }} />
            ) : (
              <IconButton
                icon={
                  user?.hasPasswordReset === "failure"
                    ? () => (
                        <Tooltip
                          title={
                            "An error has occured when resetting the password, please contact support at: support@cactus.bm"
                          }
                        >
                          <ErrorOutlineIcon />
                        </Tooltip>
                      )
                    : user?.hasPasswordReset === "success"
                    ? CheckCircleOutlineIcon
                    : LockResetIcon
                }
                onClick={onReset}
                isDisabled={
                  disabled ||
                  user?.hasPasswordReset === "success" ||
                  resettingPassword != null
                }
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>{`ID: ${user.username}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} alignItems={"center"}>
            {permissionsList.map((perm, index) => (
              <React.Fragment key={`edit_perm_${index}`}>
                <div>{`${perm} `}</div>
                <Checkbox
                  color={"primary"}
                  checked={perms.includes(perm)}
                  onClick={(e) => {
                    onChange({
                      user,
                      perms: {
                        [perm]: e.target.checked,
                      },
                    });
                  }}
                  disabled={disabled}
                />
              </React.Fragment>
            ))}
            {perms
              .filter((perm) => !permissionsList.includes(perm))
              .map((perm, index) => (
                <React.Fragment key={`no_edit_perm_${index}`}>
                  <div key={`permName_${index}`}>{`${perm} `}</div>
                  <Checkbox
                    color={"primary"}
                    checked={perms.includes(perm)}
                    disabled={true}
                  />
                </React.Fragment>
              ))}
          </Stack>
        </Grid>
        {timeouts?.length ? (
          timeouts.map(([time, pList], index) => (
            <Grid xs={12}>
              <Stack direction={"row"} alignItems={"center"}>
                <Button
                  color={"primary"}
                  checked={true}
                  onClick={(e) => {
                    onChange({
                      user,
                      timeoutDelete: [time],
                    });
                  }}
                  disabled={disabled}
                >
                  {"Delete"}
                </Button>
                <div key={`timout_msg_${index}`}>
                  {"Expire permissions "}
                  {`${time} `}
                  {pList?.length ? (
                    pList.map((perm) => <b>{`${perm} `}</b>)
                  ) : (
                    <b>{" All"}</b>
                  )}
                </div>
              </Stack>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Stack direction={"row"} alignItems={"center"}>
              <Grid item md={1}>
                <Button
                  color={"primary"}
                  checked={true}
                  onClick={(e) => {
                    onChange({
                      user,
                      timeoutAdd: {
                        [expireDate]: expireAll ? [] : expireList,
                      },
                    });
                  }}
                  disabled={disabled || invalidDate}
                >
                  {"Add expiry"}
                </Button>
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={"Expiry date/time"}
                  onChange={(e) => setExpireDate(e.target.value)}
                  value={expireDate}
                  disabled={disabled}
                  helperText={
                    "iso style: year-month-day or year-month-day hour:minute"
                  }
                />
              </Grid>
              <Grid item md={1}>
                <Stack direction={"row"} alignItems={"center"}>
                  <div>{"  All "}</div>
                  <Checkbox
                    color={"primary"}
                    checked={expireAll}
                    onChange={(e) => setExpireAll(e.target.checked)}
                    disabled={disabled}
                  />
                </Stack>
              </Grid>
              {perms.map((perm) => (
                <Grid item xs={4} md={2}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <div>{perm}</div>
                    <Checkbox
                      color={"primary"}
                      checked={expireList.includes(perm)}
                      onChange={(e) => setExpirePerm(perm, e.target.checked)}
                      disabled={disabled || expireAll}
                    />
                  </Stack>
                </Grid>
              ))}
            </Stack>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const prettyDate = (timestamp) => {
  if (timestamp == null) {
    return timestamp;
  }
  const date = new Date(timestamp);
  return (
    <Typography>{`updated on ${date.toLocaleString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })} at ${date.toLocaleString("en-us", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    })}`}</Typography>
  );
};

const NewUser = ({ saveUserLogMessage, userLogMessage, hasUserLogMessage }) => {
  const {
    data: permissionsList,
    status: permissionsListStatus,
  } = useGetAllowedUserPermissionsQuery();
  const [formikPerms, setFormikPerms] = useState([]);
  const [formikPermToPerm, setFormikPermToPerm] = useState({});
  const [formikInitialValues, setFormikInitialValues] = useState({});
  useEffect(() => {
    if (permissionsListStatus === "fulfilled") {
      const formikInitial = {
        email: "",
        enabled: true,
        sendEmail: true,
        setPassword: false,
        password: "MarmaldeRocks123!",
        timeoutRequired: false,
        timeoutDate: "",
        expireAll: true,
      };
      const entries = permissionsList?.permissions.map((p) => [
        "perm_" + p.replaceAll(/[:,-]/g, "_"),
        p,
      ]);
      entries.forEach(([formik, raw]) => {
        formikInitial[formik] = true;
        formikInitial["expire_" + formik] = true;
      });
      setFormikInitialValues(formikInitial);
      setFormikPerms(entries.map(([formik, _]) => formik));
      setFormikPermToPerm(Object.fromEntries(entries));
    }
  }, [
    permissionsList?.permissions,
    permissionsListStatus,
    setFormikInitialValues,
  ]);

  const { companyId } = useParams();

  const { enqueueSnackbar: queueMessage } = useSnackbar();
  const addMessage = (variant, message) => {
    queueMessage(message, {
      variant,
      autoHideDuration: 5_000,
      preventDuplicate: true,
    });
  };
  const [resettingPassword, setResettingPassword] = useState(null);
  const [resetPasswords, setResetPasswords] = useState({});
  const [resetUserPassword] = useResetUserPasswordMutation();
  const [newUser, setNewUser] = useState(null);
  const [usersAdded, setUsersAdded] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [createUser, { isLoading, isError }] = useCreateUserMutation();
  const [
    configureUser,
    { isLoading: configureLoading },
  ] = useConfigureUserMutation();
  const [
    triggerListUsers,
    { data: users, isFetching: usersLoading, startedTimeStamp: usersTimestamp },
  ] = useLazyListUsersQuery();
  useEffect(() => {
    if (companyId) {
      triggerListUsers({ companyId });
    }
  }, [companyId, triggerListUsers]);
  const onChange = ({ user, enabled, perms, timeoutDelete, timeoutAdd }) => {
    const config = {
      username: user.username,
      enabled,
      perms,
      timeoutDelete,
      timeoutAdd,
      userLogMessage,
    };
    configureUser(config).then((response) => {
      if (!response.data) {
        addMessage("error", `Failed to update ${user.email}`);
      } else if (response.data.changes) {
        addMessage("success", `${user.email} updated successfully`);
      } else {
        addMessage("warning", `No changes made to ${user.email}`);
      }
      triggerListUsers({ companyId });
    });
  };

  const permList = permissionsList?.permissions || [];

  if (permissionsListStatus === "rejected") {
    return (
      <Box sx={{ margin: 2, textAlign: "center" }}>
        <Typography variant={"subTitle1"}>
          {"System failure: failed to read allowed permissions"}
        </Typography>
      </Box>
    );
  }

  if (permissionsListStatus !== "fulfilled" || !formikPerms.length)
    return <BigLoader />;
  return (
    <AdminDashboard>
      <DashboardContent isFullWidth={false}>
        <Grid item xs={12}>
          <Component
            title={"Users"}
            loading={!users && usersLoading}
            options={prettyDate(usersTimestamp)}
          >
            <Grid container spacing={2}>
              {[...(users || [])].sort(userSort).map((user) => (
                <Grid key={user.username} item xs={12}>
                  <User
                    user={{
                      ...user,
                      hasPasswordReset: resetPasswords[user.email],
                    }}
                    permissionsList={permList}
                    resettingPassword={resettingPassword}
                    disabled={
                      configureLoading || usersLoading || !hasUserLogMessage
                    }
                    onChange={onChange}
                    onReset={() => {
                      setResettingPassword(user.email);
                      resetUserPassword({
                        username: user.email,
                        userLogMessage,
                        companyId,
                      })
                        .then((response) => {
                          if (response?.data) {
                            setResetPasswords({
                              ...resetPasswords,
                              [user.email]: "success",
                            });
                          } else {
                            setResetPasswords({
                              ...resetPasswords,
                              [user.email]: "failure",
                            });
                          }
                          setResettingPassword(null);
                        })
                        .catch(() => {
                          setResetPasswords({
                            ...resetPasswords,
                            [user.email]: "failure",
                          });
                          setResettingPassword(null);
                        });
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                {users && (
                  <TextField
                    variant={"filled"}
                    label={"Reason for updating users"}
                    fullWidth
                    onChange={(e) => saveUserLogMessage(e.target.value)}
                    value={userLogMessage}
                  />
                )}
              </Grid>
            </Grid>
          </Component>
        </Grid>
        {companyId && (
          <Grid item xs={12}>
            <Formik
              initialValues={formikInitialValues}
              validationSchema={yup.object({
                email: yup
                  .string("The email address of the new user.")
                  .email(({ value }) => `${value} is not a valid email`)
                  .required("Email is required"),
                timeoutRequired: yup.boolean(),
                timeoutDate: yup.string().when("timeoutRequired", {
                  is: true,
                  then: yup
                    .string()
                    .matches(
                      /^[0-9]{4}(-[0-9]{2}){2}([T ]([0-9]{2}:[0-9]{2}))?$/,
                      "Must be iso style: year-month-day or year-month-day hour:minute"
                    )
                    .required("To expire permissions a date must be provided"),
                }),
                setPassword: yup.boolean(),
                password: yup.string().when("setPassword", {
                  is: true,
                  then: yup
                    .string()
                    .min(16, "Password must be at least 16 characters long")
                    .matches(
                      /[a-z]/,
                      "Password must have at least one lowercase letter"
                    )
                    .matches(
                      /[A-Z]/,
                      "Password must have at least one uppercase letter"
                    )
                    .matches(/[0-9]/, "Password must contain a number")
                    .required("Password is required"),
                  otherwise: yup.string().notRequired(),
                }),
              })}
              onSubmit={(values) => {
                const createUserParams = {
                  ...values,
                  companyId,
                  userLogMessage,
                };
                const entries = Object.entries(values);
                const perms = entries
                  .filter(([name, val]) => formikPerms.includes(name))
                  .map(([k, v]) => [formikPermToPerm[k], v]);
                createUserParams["perms"] = Object.fromEntries(perms);
                if (values.timeoutRequired) {
                  if (values.expireAll) {
                    createUserParams["timeout"] = {
                      [values.timeoutDate]: [],
                    };
                  } else {
                    const expirePermisions = entries
                      .filter(
                        ([permission, required]) =>
                          permission.startsWith("expire_") &&
                          formikPerms.includes(permission.substring(7)) &&
                          required
                      )
                      .map(([name, v]) => formikPermToPerm[name.substring(7)]);
                    createUserParams["timeout"] = {
                      [values.timeoutDate]: expirePermisions,
                    };
                  }
                }
                createUser(createUserParams).then((response) => {
                  setResponseData(
                    `\`\`\`\n${JSON.stringify(
                      response?.data ?? response,
                      null,
                      2
                    )}\n\`\`\``
                  );
                  setNewUser(response?.data);
                  setUsersAdded(usersAdded + 1);
                  triggerListUsers({ companyId });
                  if (isError)
                    addMessage("error", `User ${values.email} creation failed`);
                  else
                    addMessage(
                      "success",
                      `User ${values.email} created successfully`
                    );
                });
              }}
            >
              {(formik) => (
                <Component title={"Create a New User"}>
                  <Grid container spacing={config.GRID_SPACING}>
                    <Grid item xs={12}>
                      <Typography>{companyId}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={"email"}
                        label={"Email Address"}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Grid item md={2}>
                          <Stack direction={"row"} alignItems={"center"}>
                            <div>{`Set Password `}</div>
                            <Checkbox
                              name={"setPassword"}
                              color={"primary"}
                              checked={formik.values.setPassword}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </Stack>
                        </Grid>
                        <TextField
                          name={"password"}
                          label={"Password"}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={
                            formik.values.setPassword
                              ? formik.values.password
                              : ""
                          }
                          disabled={!formik.values.setPassword}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction={"row"} xs={{ margin: 1 }}>
                        {formikPerms.map((perm) => (
                          <Grid item xs={4} md={2}>
                            <Stack direction={"row"} alignItems={"center"}>
                              <div>{formikPermToPerm[perm]}</div>
                              <Checkbox
                                name={perm}
                                color={"primary"}
                                checked={formik.values[perm]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Grid item md={2}>
                          <Stack direction={"row"} alignItems={"center"}>
                            <div>{`Expire permissions `}</div>
                            <Checkbox
                              name={"timeoutRequired"}
                              color={"primary"}
                              checked={formik.values.timeoutRequired}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </Stack>
                        </Grid>
                        <Grid item md={4}>
                          <TextField
                            name={"timeoutDate"}
                            label={"Expiry date/time"}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={
                              formik.values.timeoutRequired
                                ? formik.values.timeoutDate
                                : ""
                            }
                            disabled={!formik.values.timeoutRequired}
                            error={
                              formik.touched.timeoutDate &&
                              Boolean(formik.errors.timeoutDate)
                            }
                            helperText={
                              "year-month-day or year-month-day hour:minute"
                            }
                          />
                        </Grid>
                        <Grid item md={1}>
                          <Stack direction={"row"} alignItems={"center"}>
                            <div>{"  All "}</div>
                            <Checkbox
                              name={"expireAll"}
                              color={"primary"}
                              checked={formik.values.expireAll}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={!formik.values.timeoutRequired}
                            />
                          </Stack>
                        </Grid>

                        {formikPerms.map((perm) => (
                          <Grid item xs={4} md={2}>
                            <Stack direction={"row"} alignItems={"center"}>
                              <div>{formikPermToPerm[perm]}</div>
                              <Checkbox
                                name={"expire_" + perm}
                                color={"primary"}
                                checked={
                                  formik.values["expire_" + perm] ||
                                  formik.values.expireAll
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={
                                  !formik.values.timeoutRequired ||
                                  formik.values.expireAll
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Stack>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <div>{`Enabled `}</div>
                        <Checkbox
                          name={"enabled"}
                          color={"primary"}
                          checked={formik.values.enabled}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <div>{`Send Email `}</div>
                        <Checkbox
                          name={"sendEmail"}
                          color={"primary"}
                          checked={formik.values.sendEmail}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid item xs={10}>
                        <TextField
                          variant={"filled"}
                          label={"Reason to create a user"}
                          fullWidth
                          onChange={(e) => saveUserLogMessage(e.target.value)}
                          value={userLogMessage}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={formik.handleSubmit}
                          isLoading={isLoading}
                          isDisabled={isLoading || !hasUserLogMessage}
                          hasError={isError}
                          errorTooltip={"Failed to create user."}
                        >
                          {"Create User"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Component>
              )}
            </Formik>
          </Grid>
        )}
        {responseData && (
          <Component title={"Server Response"}>
            <Grid item xs={12}>
              <Typography>{"Response:"}</Typography>
            </Grid>
            {newUser && (
              <Grid item xs={12}>
                <User
                  permissionsList={permList}
                  user={newUser}
                  disabled={true}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <MDEditor.Markdown source={responseData} />
            </Grid>
            <Grid item xs={12}>
              {" "}
            </Grid>
          </Component>
        )}
      </DashboardContent>
    </AdminDashboard>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogMessage: adminSelectors.userLogMessage(state),
    hasUserLogMessage: adminSelectors.hasUserLogMessage(state),
  };
};

const mapDispatchToProps = {
  saveUserLogMessage: adminActions.userLogMessage.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
