import { createSlice } from "@reduxjs/toolkit";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const initialState = {
  texts: {},
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    updateNoteText: (state, action) => {
      return {
        ...state,
        texts: {
          ...state?.texts,
          [action.payload.key]: action.payload.text,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => action?.payload?.data?.state?.notes ?? initialState
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, () => initialState)
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, (state, action) => {
        const renewalConfig = staticDataSelectors.selectConfig("renewal")(
          action?.payload?.priorState
        );
        const renewalAction =
          renewalConfig?.byKey?.["notes.texts"]?.action ?? "drop";

        if (renewalAction === "keep") {
          return {
            ...initialState,
            texts: state?.texts ?? {},
          };
        } else if (renewalAction === "keep_with_renewal_message") {
          return {
            ...initialState,
            texts: Object.fromEntries(
              Object.entries(state?.texts ?? {})
                .filter(([, v]) => !!v)
                .map(([k, v]) => [
                  k,
                  `**Carried forward from the prior submission:**\n\n${v}`,
                ])
            ),
          };
        } else {
          return { ...initialState };
        }
      });
  },
});

export const selectNotes = (state) => state?.notes ?? {};

export const selectNoteText = (key) => (state) =>
  selectNotes(state).texts?.[key];

export const { updateNoteText } = notesSlice.actions;

export default notesSlice;
