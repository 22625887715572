import * as staticDataService from "../../../services/staticDataService";
import { useGetColumnMappingSuggestionsQuery } from "../../../services/suggestionService";
import {
  useGetColumnTypeSummaryQuery,
  useGetHeaderRowQuery,
  useGetTablePageQuery,
} from "../../../services/tablesService";
import CentredLoader from "./CentredLoader";
import {
  EditRounded as EditIcon,
  CheckRounded as ActiveIcon,
} from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import * as numbers from "common/numbers";
import Button from "components/common/Button";
import DefaultLoader from "components/common/DefaultLoader";
import InlineSearch from "components/common/InlineSearch";
import { Fragment, useEffect, useState } from "react";
import * as utils from "utils";

const HeaderAction = ({
  mappedColumn,
  requiredMissing,
  tip,
  isLoading,
  onClick,
}) => {
  if (isLoading) {
    return (
      <DefaultLoader
        color={"#fa7b35"}
        type={"TailSpin"}
        size={18}
        style={{ marginRight: "6px", fontSize: "18px", lineHeight: "12px" }}
      />
    );
  }

  if (mappedColumn) {
    return (
      <IconButton onClick={onClick}>
        <EditIcon />
      </IconButton>
    );
  }

  const title = requiredMissing
    ? "This column is required"
    : tip || "This column is optional";
  const icon = requiredMissing ? <EditIcon /> : <EditIcon />;

  return (
    <Tooltip title={title} arrow>
      <IconButton onClick={onClick}>{icon}</IconButton>
    </Tooltip>
  );
};

const columnType = ({ numbers, dates, strings }) => {
  const numbersCount = numbers?.count ?? 0;
  const datesCount = dates?.count ?? 0;
  const stringsCount = strings?.count ?? 0;
  if (numbersCount + datesCount > stringsCount / 2) {
    return numbersCount > datesCount ? "numbers" : "dates";
  }
  return "strings";
};

const LabelledValue = ({ label, value, margin }) => (
  <Typography variant={"caption"} display={"block"} marginBottom={margin}>
    <b>{label}</b>
    {value}
  </Typography>
);

const ColumnLabel = ({ index, margin }) => (
  <LabelledValue
    label={`Column ${numbers.numberToAlphabetical(index)}`}
    margin={margin ?? 1}
  />
);

const NumberSummary = ({ min, max, columnIndex }) => (
  <>
    <ColumnLabel index={columnIndex} />
    <LabelledValue label={"Min: "} value={min} />
    <LabelledValue label={"Max: "} value={max} />
  </>
);

const DateSummary = ({ min, max, columnIndex }) => (
  <>
    <ColumnLabel index={columnIndex} />
    <LabelledValue label={"From: "} value={min} />
    <LabelledValue label={"To: "} value={max} />
  </>
);

const StringValueCount = ({ value, count, tooltip }) => {
  let label = value || "[BLANK]";
  label += count != null ? ": " : "";

  return tooltip == null ? (
    <LabelledValue label={label} value={count} />
  ) : (
    <Tooltip title={tooltip} placement={"right"}>
      <LabelledValue label={label} value={count} />
    </Tooltip>
  );
};

export const summarizeStringValues = ({ count, blanks, values }) => {
  if (count === 0) {
    return [{ value: blanks > 0 ? "All Blank" : "No values" }];
  }
  if (values.length === 1) {
    return [{ value: `All (${values[0][1]}): ${values[0][0]}` }];
  }
  let multi = values
    .filter(([v, c]) => c > 1)
    .map(([value, count]) => ({
      value: value.length > 24 ? `${value.slice(0, 24)}…` : value,
      tooltip: value.length > 24 ? value : undefined,
      count,
    }))
    .slice(0, 5);
  let various = count - multi.reduce((acc, cur) => (acc += cur.count), 0);
  if (various > 0) {
    multi = multi.slice(0, 4);
  }
  various = count - multi.reduce((acc, cur) => (acc += cur.count), 0);
  return [
    ...multi,
    ...(various === 0 ? [] : [{ value: "Various", count: various }]),
  ];
};

const StringSummary = ({ count, values, blanks, columnIndex }) => {
  const summary = summarizeStringValues({ count, blanks, values });
  return (
    <>
      <ColumnLabel index={columnIndex} />
      {summary.map(({ value, count, tooltip }) => (
        <StringValueCount value={value} count={count} tooltip={tooltip} />
      ))}
    </>
  );
};

const ColumnSummary = ({ name, columnIndex, summary, active }) => {
  const hasSummary = !utils.isEmptyObject(summary);
  const type = hasSummary ? columnType(summary) : null;

  let title = <ColumnLabel index={columnIndex} margin={0} />;

  if (type === "numbers") {
    title = <NumberSummary {...summary.numbers} columnIndex={columnIndex} />;
  } else if (type === "dates") {
    title = <DateSummary {...summary.dates} columnIndex={columnIndex} />;
  } else if (type === "strings") {
    title = (
      <StringSummary
        {...summary.strings}
        blanks={summary.blanks}
        columnIndex={columnIndex}
      />
    );
  }

  return (
    <Tooltip title={title} placement={"right"}>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Stack>
          <Typography variant={"subtitle1"}>{name}</Typography>
          {hasSummary && (
            <Typography variant={"caption"}>
              {summary.strings?.values?.[0]?.[0] ?? "-"}
            </Typography>
          )}
        </Stack>
        {active && <ActiveIcon />}
      </Stack>
    </Tooltip>
  );
};

const ColumnMappingHeader = ({
  title,
  mappedColumn,
  requiredMissing,
  tip,
  isLoading,
  onClick,
}) => {
  const background = requiredMissing
    ? "#ffefee"
    : mappedColumn == null
    ? "#f3f4f7"
    : "#effff7";
  return (
    <TableCell
      sx={{
        padding: "4px 4px 4px 8px",
        verticalAlign: "top !important",
        borderRight: "1px solid gainsboro",
        background: background,
      }}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Stack>
          <Typography variant={"subtitle1"}>{title}</Typography>
          <Typography variant={"caption"}>
            {isLoading ? "-" : mappedColumn || "Unmapped"}
          </Typography>
        </Stack>
        <HeaderAction
          mappedColumn={mappedColumn}
          requiredMissing={requiredMissing}
          tip={tip}
          isLoading={isLoading}
          onClick={onClick}
        />
      </Stack>
    </TableCell>
  );
};

const FULFILLED = "fulfilled";

const ColumnMapper = ({
  fileId,
  origin,
  tableName,
  headerRows,
  updateColumns,
  targetColumnList,
  setTargetColumnList,
  targetColumnMap,
  setTargetColumnMap,
  sourceHeaders,
  setSourceHeaders,
  outputColumns,
  tableSummary,
  parentRef,
}) => {
  const {
    data: configuration,
    status: configurationStatus,
  } = staticDataService.useMultiConfigQuery(["claims_files", "translations"]);

  const request = {
    fileId,
    tableName,
    skipRows: origin.row,
    skipColumns: origin.column,
    headerRows,
  };

  const {
    data: sourceColumns,
    status: sourceColumnsStatus,
  } = useGetHeaderRowQuery(request);

  const {
    data: columnTypes,
    status: columnTypesStatus,
  } = useGetColumnTypeSummaryQuery(request);

  useEffect(() => {
    if (
      configurationStatus === FULFILLED &&
      sourceColumnsStatus === FULFILLED
    ) {
      const notEqual = (listA, listB) => {
        if (listA?.length !== listB?.length) return true;
        if (listA === listB) return false;
        for (const i in listA) if (listA[i] !== listB[i]) return true;
        return false;
      };

      const headers = sourceColumns?.header ?? [];
      let headersChanged = notEqual(headers, sourceHeaders);
      if (headersChanged) {
        setSourceHeaders(headers);
      }

      const colNames = outputColumns.map((c) => c.column);
      const colNamesChanged = notEqual(targetColumnList, colNames);
      if (colNamesChanged) {
        setTargetColumnList(colNames);
      }
      if (colNamesChanged || headersChanged) {
        const targetMap = {};
        outputColumns.forEach((col) => {
          targetMap[col.column] = {
            column: col.column,
            label: col.display,
            required: col.required,
            tip: col.tip,
            suggestions: null,
            mappedColumnIndex: null,
            mappedColumnName: null,
          };
        });
        setTargetColumnMap(targetMap);
      }
    }
  }, [
    sourceColumns,
    sourceColumnsStatus,
    configuration,
    configurationStatus,
    fileId,
    tableName,
    targetColumnMap,
    targetColumnList,
    setTargetColumnList,
    setTargetColumnMap,
    sourceHeaders,
    setSourceHeaders,
    outputColumns,
  ]);

  const {
    data: suggestions,
    status: suggestionsStatus,
  } = useGetColumnMappingSuggestionsQuery(columnTypes?.types, {
    skip: !columnTypes || columnTypesStatus !== FULFILLED,
  });

  const { data: dataPage, status: dataPageStatus } = useGetTablePageQuery(
    {
      ...request,
      skipRows: request.skipRows + (request.headerRows || 1),
    },
    { skip: !fileId || !tableName }
  );

  const columnNameMapping = (targetName, sourceColumn) => {
    return {
      outputName: targetName,
      inputColumn: sourceColumn,
      conversionType: ["_OPEN_CLOSED", "_COVERAGE"].includes(targetName)
        ? "CONVERT"
        : ["_EXCLUDE"].includes(targetName)
        ? "IN_DELETE_ROW"
        : "",
    };
  };

  useEffect(() => {
    const suggestionsList = suggestions?.suggestions;
    if (suggestionsStatus === FULFILLED && sourceColumnsStatus === FULFILLED) {
      let changed = false;
      const init = { ...targetColumnMap };
      const columnUpdates = [];
      for (const column in targetColumnMap) {
        const suggest = suggestionsList[column];
        if (!!suggest && !init[column].suggestions) {
          init[column] = {
            ...targetColumnMap[column],
            suggestions: suggest,
          };
          if (
            init[column].mappedColIndex == null &&
            (suggest?.length || 0) > 0 &&
            suggest[0].confidence > 0.1
          ) {
            const suggestedIndex = sourceColumns?.header.indexOf(
              suggest[0].suggestion
            );
            init[column].mappedColIndex = suggestedIndex;
            init[column].mappedColName = suggest[0].suggestion;
            columnUpdates.push(columnNameMapping(column, suggestedIndex));
          }
          changed = true;
        }
      }
      if (changed) {
        setTargetColumnMap(init);
        updateColumns(columnUpdates);
      }
    }
  }, [
    suggestionsStatus,
    suggestions,
    targetColumnMap,
    sourceColumns,
    sourceColumnsStatus,
    updateColumns,
    setTargetColumnMap,
  ]);

  const [editColumn, setEditColumn] = useState(null);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    if (editColumn != null && parentRef.current) {
      parentRef.current.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [editColumn, parentRef]);

  const updateMapping = (index, name) => {
    if (editColumn != null) {
      const targetColumnName = targetColumnList[editColumn];
      const colEntry = targetColumnMap[targetColumnName];
      if (colEntry.mappedColIndex !== index) {
        const updatedTargetColumns = {
          ...targetColumnMap,
          [targetColumnName]: {
            ...targetColumnMap[targetColumnName],
            mappedColIndex: index,
            mappedColName: name,
          },
        };
        setTargetColumnMap(updatedTargetColumns);
        updateColumns([columnNameMapping(targetColumnName, index)]);
      }
      setEditColumn(null);
    }
  };

  if (
    suggestionsStatus !== FULFILLED ||
    sourceColumnsStatus !== FULFILLED ||
    dataPageStatus !== FULFILLED
  ) {
    return <CentredLoader />;
  }
  const page = dataPage?.page || [];
  const hasSearchText = (searchText ?? "").trim() !== "";
  const editColumns = (sourceColumns?.header ?? [])
    .map((col, index) => {
      return { name: col, position: index };
    })
    .filter(
      (col) =>
        !hasSearchText ||
        col.name.toLowerCase().includes(searchText.trim().toLowerCase())
    );

  const toggleEditColumn = (newColumn) => {
    setSearchText(null);
    setEditColumn(newColumn === editColumn ? null : newColumn);
  };
  return (
    <TableContainer sx={{ overflowX: "initial" }}>
      <Table
        stickyHeader
        sx={{
          background: "white",
          borderTop: "1px solid gainsboro",
          borderLeft: "1px solid gainsboro",
        }}
      >
        <TableHead>
          <TableRow>
            {targetColumnList.map((targetColName, targetColIndex) => {
              const targetColumn = targetColumnMap[targetColName];
              return (
                <ColumnMappingHeader
                  key={targetColIndex}
                  title={targetColumn.label}
                  mappedColumn={
                    targetColumn.mappedColIndex == null
                      ? null
                      : sourceColumns.header?.[targetColumn.mappedColIndex]
                  }
                  requiredMissing={
                    targetColumn.mappedColIndex == null && targetColumn.required
                  }
                  tip={targetColumn.tip}
                  isLoading={false}
                  onClick={() => toggleEditColumn(targetColIndex)}
                />
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {page.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {targetColumnList.map((colName, colIndex) => {
                const colEntry = targetColumnMap?.[colName] || {};
                const editColEntry =
                  editColumn == null
                    ? {}
                    : targetColumnMap?.[targetColumnList[editColumn]];
                return editColumn != null &&
                  editColumn === colIndex &&
                  rowIndex !== 0 ? (
                  <Fragment key={colIndex}></Fragment>
                ) : editColumn === colIndex && rowIndex === 0 ? (
                  <TableCell
                    key={colIndex}
                    rowSpan={page.length}
                    sx={{
                      verticalAlign: "top",
                      borderRight: "1px solid gainsboro",
                    }}
                    className={"fade-in"}
                  >
                    <Stack>
                      <InlineSearch
                        placeholder={"Filter spreadsheet columns"}
                        onChange={(searchText) => setSearchText(searchText)}
                        fullWidth
                      />
                      <List sx={{ width: "100%" }}>
                        {editColumns.map((col) => (
                          <ListItem key={col.position} sx={{ padding: 0 }}>
                            <ListItemButton
                              onClick={() =>
                                updateMapping(col.position, col.name)
                              }
                            >
                              <ColumnSummary
                                name={col.name}
                                columnIndex={col.position + origin.column}
                                summary={tableSummary[col.position] ?? {}}
                                active={
                                  col.position === editColEntry.mappedColIndex
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                        {hasSearchText && editColumns.length === 0 && (
                          <Typography
                            margin={2}
                            color={"dimgray"}
                            align={"center"}
                          >
                            {"No matches for '"}
                            {searchText.trim()}
                            {"'"}
                          </Typography>
                        )}
                      </List>
                      <Stack gap={1}>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() => setEditColumn(null)}
                        >
                          {"Done"}
                        </Button>
                        <Button
                          sx={{ width: "100%" }}
                          onClick={() => updateMapping(null, null)}
                          color={"secondary"}
                        >
                          {"Clear Mapping"}
                        </Button>
                      </Stack>
                    </Stack>
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{ borderRight: "1px solid gainsboro" }}
                    key={"display-cell-" + rowIndex + "-" + colIndex}
                  >
                    {colEntry.mappedColIndex == null
                      ? ""
                      : row[colEntry.mappedColIndex]}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ColumnMapper;
