import { createApi } from "@reduxjs/toolkit/query/react";
import * as logger from "common/logger";
import * as config from "config";
import * as FileSaver from "file-saver";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.reports(args.url),
      {
        method: "post",
        ...args.payload,
      }
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    report: builder.query({
      queryFn: async ({
        type,
        args,
        format,
        filename,
        pollingIntervalSeconds = 1,
      }) => {
        try {
          let response = await utils.authenticatedFetch(
            config.endpoints.reports("trigger-report"),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ type, args, format, filename }),
            }
          );
          const creationResponse = await response.json();
          const reportId = creationResponse.id;

          const delay = (seconds) =>
            new Promise((res) => setTimeout(res, 1000 * seconds));

          let status = null;
          while (
            status == null ||
            ["pending", "running"].includes(status.state)
          ) {
            await delay(pollingIntervalSeconds);
            response = await utils.authenticatedFetch(
              config.endpoints.reports("retrieve-report"),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: reportId }),
              }
            );
            status = await response.json();
          }
          if (status.state !== "succeeded") {
            throw new Error(status);
          }
          response = await fetch(status.downloadUrl);
          const data = await response.json();
          return { data };
        } catch (e) {
          logger.exception(e);
          if (e.name === "FORBIDDEN") {
            return {
              error: "You do not have permission to access this report",
            };
          } else {
            return {
              error: `Error generating report. Please contact ${config.SUPPORT_EMAIL}`,
            };
          }
        }
      },
    }),
    downloadReport: builder.mutation({
      queryFn: async ({
        type,
        args,
        format,
        filename,
        pollingIntervalSeconds = 1,
      }) => {
        try {
          let response = await utils.authenticatedFetch(
            config.endpoints.reports("trigger-report"),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ type, args, format, filename }),
            }
          );
          const creationResponse = await response.json();
          const reportId = creationResponse.id;

          const delay = (seconds) =>
            new Promise((res) => setTimeout(res, 1000 * seconds));

          let status = null;
          while (
            status == null ||
            ["pending", "running"].includes(status.state)
          ) {
            await delay(pollingIntervalSeconds);
            response = await utils.authenticatedFetch(
              config.endpoints.reports("retrieve-report"),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: reportId }),
              }
            );
            status = await response.json();
          }
          if (status.state !== "succeeded") {
            throw new Error(status);
          }
          response = await fetch(status.downloadUrl);
          const blob = await response.blob();
          FileSaver.saveAs(blob, filename);
          return { data: "succeeded" };
        } catch (e) {
          logger.exception(e);
          if (e.name === "FORBIDDEN") {
            return {
              error: "You do not have permission to access this report",
            };
          } else {
            return {
              error: `Error generating report. Please contact ${config.SUPPORT_EMAIL}`,
            };
          }
        }
      },
    }),
  }),
});

export const { useReportQuery, useDownloadReportMutation } = reportApi;
