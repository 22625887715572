import BrokerCreationTemplate from "./BrokerCreationTemplate";
import PersonSelect from "./PersonSelect";
import Disabled from "components/common/Disabled";
import { useSelector, useDispatch } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as peopleActions from "store/actions/people/peopleActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import * as peopleSelectors from "store/selectors/people/peopleSelectors";

const PuniBrokerCreation = ({ onCreate, onClose }) => {
  const { data: config } = staticDataService.useConfigQuery("puni_brokers");
  const brokers = config?.all ?? [];

  const [createBroker] = staticDataService.useCreatePuniBrokerMutation();

  return (
    <BrokerCreationTemplate
      brokers={brokers}
      createBroker={createBroker}
      visibilityBaseTag={"<BrokerCreation>"}
      formId={"puni-broker-creation-form"}
      title={"Add a new puni broker"}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
};

const PuniBroker = () => {
  const {
    data: brokers,
    refetch: refreshBrokers,
  } = staticDataService.useConfigQuery("puni_brokers");

  const dispatch = useDispatch();

  const broker = useSelector(peopleSelectors.selectPuniBroker);

  const updateBroker = (value) => {
    dispatch(peopleActions.updatePuniBroker(value));
  };

  const sortedBrokers = [...(brokers?.all ?? [])].sort(
    (a, b) =>
      (a.company || "z").localeCompare(b.company || "z") ||
      (a.name || "z").localeCompare(b.name || "z")
  );

  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  return (
    <Disabled ifReadOnly>
      <PersonSelect
        person={broker}
        personList={sortedBrokers}
        onUpdatePerson={updateBroker}
        onUpdatePersonList={refreshBrokers}
        PersonCreationComponent={PuniBrokerCreation}
        label={translateLabel("PUNI_BROKER", {
          default: "Puni Broker",
        })}
        showLabelOnCard
      />
    </Disabled>
  );
};

export default PuniBroker;
