import { Add as AddIcon } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Stack,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import * as logger from "common/logger";
import * as objects from "common/objects";
import Component from "components/Component";
import Button from "components/common/Button";
import TextField from "components/common/TextField";
import Visible from "components/common/Visible";
import * as config from "config";
import * as _ from "lodash";
import { useState } from "react";

const SelectFromChoices = ({
  label,
  value,
  onChange,
  choices,
  onAddChoice,
}) => {
  return (
    <Autocomplete
      options={choices}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          type={"text"}
          variant={"filled"}
          InputLabelProps={{ shrink: true }}
          label={label}
          fullWidth
        />
      )}
      filterOptions={(options, state) => {
        const inputValue = (state?.inputValue ?? "").trim();
        const filteredOptions = objects
          .search(
            options.map((x) => ({ id: x })),
            {
              search: inputValue.split(/\s+/),
              keys: ["id"],
            }
          )
          .map((x) => x.id);
        return onAddChoice && inputValue.length
          ? [...filteredOptions, { addNew: true, value: inputValue }]
          : filteredOptions;
      }}
      getOptionLabel={(x) => (x?.addNew ? `Add New: ${x.value}` : x)}
      renderOption={(props, option, state) => {
        if (option.addNew) {
          return (
            <Box {...props} sx={{ margin: 1 }}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AddIcon />
                <Typography>
                  {"Add New: "}
                  {option.value}
                </Typography>
              </Stack>
            </Box>
          );
        } else {
          return (
            <Box {...props} sx={{ margin: 1 }}>
              <Typography>{option}</Typography>
            </Box>
          );
        }
      }}
      value={value}
      onChange={(_, value, reason) => {
        if (reason === "selectOption" && value) {
          if (value.addNew && value.value) {
            onAddChoice && onAddChoice(value.value);
          } else {
            onChange(value);
          }
        } else if (reason === "clear") {
          onChange(null);
        }
      }}
    />
  );
};

const BrokerCreationTemplate = ({
  brokers,
  createBroker,
  defaultVisibilityOverrideById,
  visibilityBaseTag,
  formId,
  title,
  onCreate,
  onClose,
}) => {
  const [brokerInputs, setBrokerInputs] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const [companies, setCompanies] = useState(() =>
    _.chain(brokers ?? [])
      .map((x) => x.company)
      .filter((x) => x != null)
      .uniq()
      .sort()
      .value()
  );

  const BROKER_FIELDS = [
    {
      id: "name",
      label: "Contact Name",
      defaultVisibility: true,
    },
    {
      id: "email",
      label: "Email",
      defaultVisibility: true,
    },
    {
      id: "workNumber",
      label: "Work Number",
      defaultVisibility: true,
    },
    {
      id: "mobileNumber",
      label: "Mobile Number",
      defaultVisibility: true,
    },
    {
      id: "company",
      label: "Broking House",
      choices: companies,
      defaultVisibility: true,
      onAddChoice: (choice) =>
        setCompanies(
          _.chain([...companies, choice])
            .uniq()
            .sort()
            .value()
        ),
    },
    {
      id: "address",
      label: "Address",
      rows: 3,
      defaultVisibility: false,
    },
    {
      id: "licenseNumber",
      label: "License Number",
      defaultVisibility: false,
    },
    {
      id: "location",
      label: "Location",
      defaultVisibility: true,
    },
  ];

  const saveBroker = async () => {
    setIsSaving(true);
    setError(null);
    try {
      const { data: broker, error } = await createBroker(brokerInputs);
      if (error) {
        logger.exception(error);
        setError("Failed to create a new broker");
      } else {
        onCreate(broker);
      }
    } catch (error) {
      logger.exception(error);
      setError("Failed to create a new broker");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Component title={title}>
      <form id={formId}>
        <Stack direction={"column"} spacing={config.GRID_SPACING}>
          {BROKER_FIELDS.map((field) => {
            const value = brokerInputs[field.id];
            const updateValue = (newValue) =>
              setBrokerInputs({
                ...brokerInputs,
                [field.id]: newValue || undefined,
              });

            return (
              <Visible
                key={field.id}
                byTag={`${visibilityBaseTag ?? "<BrokerCreation>"}/${field.id}`}
                defaultVisible={
                  defaultVisibilityOverrideById?.[field.id] ??
                  field.defaultVisibility
                }
              >
                {field.choices != null ? (
                  <SelectFromChoices
                    label={field.label}
                    value={value ?? null}
                    onChange={updateValue}
                    choices={field.choices}
                    onAddChoice={(choice) => {
                      field.onAddChoice(choice);
                      updateValue(choice);
                    }}
                  />
                ) : (
                  <TextField
                    label={field.label}
                    value={value ?? ""}
                    onChange={(x) => updateValue(x.target.value)}
                    multiline={!!field.rows}
                    rows={field.rows}
                  />
                )}
              </Visible>
            );
          })}
          <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
            <Button
              color={"secondary"}
              onClick={() => onClose()}
              isDisabled={isSaving}
            >
              {"Cancel"}
            </Button>
            <Button onClick={saveBroker} isLoading={isSaving}>
              {"Save"}
            </Button>
          </Stack>
          {error && <Alert severity={"error"}>{error}</Alert>}
        </Stack>
      </form>
    </Component>
  );
};

export default BrokerCreationTemplate;
