import SignOutButton from "../layout/SignOutButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { decodeJWT } from "aws-amplify/auth";
import * as logger from "common/logger";
import * as config from "config";
import { useEffect, useState } from "react";
import * as utils from "utils";

const useTokenClaims = () => {
  const [claims, setClaims] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const session = await utils.getSession();
      const token = session.tokens?.accessToken?.toString();
      const jwt = await decodeJWT(token);
      setClaims(jwt.payload);
    };
    fetchToken().catch(logger.exception);
  }, [setClaims]);

  return claims;
};

const UnconfiguredUserDialog = () => {
  const tokenClaims = useTokenClaims();

  const isUserUnconfigured =
    tokenClaims != null && tokenClaims?.["custom:company_id"] == null;

  return isUserUnconfigured ? (
    <Dialog open onClose={() => {}} maxWidth={"md"} fullWidth>
      <DialogTitle>{"Not Set Up"}</DialogTitle>
      <DialogContent>
        {"You have not yet been set up in Marmalade. Please contact "}
        <a href={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</a>
        {"."}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "0 24px 18px 24px",
        }}
      >
        <SignOutButton
          disableElevation
          variant={"contained"}
          color={"secondary"}
        />
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
};

export default UnconfiguredUserDialog;
