import { createApi } from "@reduxjs/toolkit/query/react";
import * as logger from "common/logger";
import * as config from "config";
import * as utils from "utils";

const stripSpaces = (obj) => {
  if (typeof obj === "string") return obj.trim();
  if (Array.isArray(obj)) return obj.map(stripSpaces);
  if (obj && typeof obj === "object")
    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [k, stripSpaces(v)])
    );
  return obj;
};

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.staticData(args.url),
      {
        header: {
          "Content-Type": "application/json",
        },
        method: args.method ?? "post",
        body: args.body,
      }
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    logger.exception(e);
    return {
      error: String(e),
    };
  }
};

export const staticDataApi = createApi({
  reducerPath: "staticDataApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    multiConfig: builder.query({
      query: (keys) => ({
        url: "retrieve-config",
        body: JSON.stringify({ keys }),
      }),
    }),
    config: builder.query({
      query: (name) => ({
        url: "retrieve-config",
        body: JSON.stringify({
          keys: [name],
        }),
      }),
      transformResponse: (response, _, arg) => response?.[arg] ?? null,
    }),
    listConfig: builder.query({
      query: () => ({
        url: "config",
        method: "get",
      }),
    }),
    featureFlags: builder.query({
      query: () => ({
        url: "feature-flags",
        method: "get",
      }),
    }),
    listBrokers: builder.query({
      query: () => ({
        url: "list-brokers",
      }),
    }),
    retrieveAuthConfig: builder.query({
      queryFn: async () => {
        try {
          const result = await utils.plainFetch(
            config.endpoints.staticData("retrieve-auth-config"),
            null,
            {
              method: "post",
            }
          );
          const data = await result.json();
          return { data };
        } catch (e) {
          logger.exception(e);
          return {
            error: String(e),
          };
        }
      },
    }),
    retrieveNavigationConfig: builder.query({
      query: () => ({
        url: "retrieve-navigation-config",
      }),
    }),
    adminRetrieveNavigationConfig: builder.query({
      query: ({ companyId }) => ({
        url: "admin-retrieve-navigation-config",
        body: JSON.stringify({ companyId }),
      }),
    }),
    createBroker: builder.mutation({
      query: (broker) => ({
        url: "create-broker",
        body: JSON.stringify(stripSpaces(broker)),
      }),
    }),
    createPuniBroker: builder.mutation({
      query: (broker) => ({
        url: "create-puni-broker",
        body: JSON.stringify(stripSpaces(broker)),
      }),
    }),
    createRetailBroker: builder.mutation({
      query: (broker) => ({
        url: "create-retail-broker",
        body: JSON.stringify(stripSpaces(broker)),
      }),
    }),
    createSurplusBroker: builder.mutation({
      query: (broker) => ({
        url: "create-surplus-broker",
        body: JSON.stringify(stripSpaces(broker)),
      }),
    }),
  }),
});

export const {
  useListConfigQuery,
  useConfigQuery,
  useLazyConfigQuery,
  useMultiConfigQuery,
  useFeatureFlagsQuery,
  useListBrokersQuery,
  useCreateBrokerMutation,
  useCreatePuniBrokerMutation,
  useCreateRetailBrokerMutation,
  useCreateSurplusBrokerMutation,
  useRetrieveAuthConfigQuery,
  useRetrieveNavigationConfigQuery,
  useAdminRetrieveNavigationConfigQuery,
} = staticDataApi;

export const useFeatureFlag = (flag, { default: def } = {}) => {
  const { data: featureFlags } = useFeatureFlagsQuery();
  return featureFlags?.[flag] ?? def ?? false;
};
