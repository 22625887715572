import { OpenInNew as OpenLinkIcon } from "@mui/icons-material";
import { Alert, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as staticDataService from "services/staticDataService";
import * as submissionsService from "services/submissionsService";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

const SaveConflictAlert = () => {
  const [activeSavedToken, setActiveSavedToken] = useState(null);
  const savedToken = useSelector(persistenceSelectors.selectSavedToken);
  const submissionId = useSelector(persistenceSelectors.selectSubmissionId);

  const { data: generalConfigurationConfig } = staticDataService.useConfigQuery(
    "general_configuration"
  );

  const {
    currentData: saveInfo,
    refetch: refetchSaveInfo,
  } = submissionsService.useRetrieveSubmissionSaveInfoQuery(
    { submissionId },
    {
      skip: !submissionId,
      pollingInterval:
        generalConfigurationConfig?.saveConflictPollingFrequencyMs ?? 5_000,
    }
  );

  useEffect(() => {
    // When we save a submission, there will be some time where the savedToken
    // corresponds to the updated submission but the latestSavedToken still corresponds
    // to the old submission. This would cause the warning to be raised incorrectly.
    //
    // To prevent this, keep an extra state of the activeSavedToken. This gets updated
    // when the savedToken changes after the save info query has returned. While this
    // is out of line with the savedToken, the test is invalid.
    refetchSaveInfo().finally(() => setActiveSavedToken(savedToken));
  }, [refetchSaveInfo, savedToken]);

  const latestSavedToken = saveInfo?.savedToken;

  if (
    !latestSavedToken ||
    savedToken !== activeSavedToken ||
    latestSavedToken === savedToken
  ) {
    return <></>;
  } else {
    const latestSavedBy = saveInfo?.savedBy ?? "Another user";
    return (
      <Alert
        severity={"error"}
        sx={{
          position: "fixed",
          left: "1rem",
          bottom: "1rem",
          boxShadow: "0 0 0 3px inset #d32f2f",
          zIndex: 99,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
        >
          <Typography>
            {`${latestSavedBy} has saved changes to the submission since you opened it.`}
          </Typography>
          <Link target={"_blank"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              spacing={0.5}
            >
              <Typography>{"Open latest version"}</Typography>
              <OpenLinkIcon fontSize={"small"} />
            </Stack>
          </Link>
        </Stack>
      </Alert>
    );
  }
};

export default SaveConflictAlert;
