import BrokerCreationTemplate from "./BrokerCreationTemplate";
import PersonSelect from "./PersonSelect";
import Disabled from "components/common/Disabled";
import { useSelector, useDispatch } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as peopleActions from "store/actions/people/peopleActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import * as peopleSelectors from "store/selectors/people/peopleSelectors";

const SurplusBrokerCreation = ({ onCreate, onClose }) => {
  const { data: config } = staticDataService.useConfigQuery("surplus_brokers");
  const brokers = config?.all ?? [];

  const [createBroker] = staticDataService.useCreateSurplusBrokerMutation();

  return (
    <BrokerCreationTemplate
      brokers={brokers}
      createBroker={createBroker}
      visibilityBaseTag={"<SurplusBrokerCreation>"}
      defaultVisibilityOverrideById={{
        name: true,
        email: false,
        workNumber: false,
        mobileNumber: false,
        company: true,
        address: false,
        licenseNumber: true,
        location: false,
      }}
      formId={"surplus-broker-creation-form"}
      title={"Add a new surplus lines broker"}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
};

const SurplusBroker = () => {
  const {
    data: brokers,
    refetch: refreshBrokers,
  } = staticDataService.useConfigQuery("surplus_brokers");

  const dispatch = useDispatch();

  const broker = useSelector(peopleSelectors.selectSurplusBroker);

  const updateBroker = (value) => {
    dispatch(peopleActions.updateSurplusBroker(value));
  };

  const sortedBrokers = [...(brokers?.all ?? [])].sort(
    (a, b) =>
      (a.company || "z").localeCompare(b.company || "z") ||
      (a.name || "z").localeCompare(b.name || "z")
  );

  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  return (
    <Disabled ifReadOnly>
      <PersonSelect
        person={broker}
        personList={sortedBrokers}
        onUpdatePerson={updateBroker}
        onUpdatePersonList={refreshBrokers}
        PersonCreationComponent={SurplusBrokerCreation}
        label={translateLabel("SURPLUS_LINES_BROKER", {
          default: "Surplus Lines Broker",
        })}
        showLabelOnCard
        showLicenseNumber
        searchProps={{
          showLicenseNumber: true,
        }}
      />
    </Disabled>
  );
};

export default SurplusBroker;
