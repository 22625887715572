import TopNav from "./TopNav";

const DashboardHeader = ({
  tabs,
  isFullWidth,
  parentPath,
  navigationState,
  setNavigationState,
  topNavBorderless,
  sticky,
}) => {
  return (
    <TopNav
      tabs={tabs}
      parentPath={parentPath}
      navigationState={navigationState}
      setNavigationState={setNavigationState}
      isFullWidth={isFullWidth}
      borderless={topNavBorderless}
      sticky={sticky}
    />
  );
};

export default DashboardHeader;
