export const NOT_SAVED_INTER_PAGE_WARNING = "not-saved-warning-inter-page";
export const NOT_SAVED_APP_EXIT_WARNING = "not-saved-warning-app-exit";
export const CREATE_CLAIMS_COVERAGE_LIST = "claims-merger/create-coverage-list";
export const UPLOAD_CLAIMS_LOSS_FILES_VIA_FILES_SERVICE =
  "claims-loss-files/upload-via-files-service";
export const CLAIMS_V2_BACKEND = "claims-v2/backend";
export const APP_BAR_ISSUE_FEEDBACK = "app-bar.Issue-Feedback";
export const SUB_STAT_REPORT_SHOW_PROGRAM_REFERENCE =
  "SubmissionStatusReport.show-program-reference";
export const SUB_STAT_REPORT_SHOW_LAYER_REFERNCE =
  "SubmissionStatusReport.show-layer-reference";
export const REVIEW_REPORTS_SHOW_ALL_VALUES_IN_FILTERS =
  "ReviewReports.show-all-values-in-filters";
export const SOV_DOWNLOAD_BUTTON_ENABLED = "SOV-download-enabled";
export const DYNAMIC_AUTO_COMPLETE = "ui/dynamic-auto-complete";
const flags = {
  visible: [
    { tag: "persistentFullWidth" },
    { tag: "saveConflictAlert" },
    { tag: "review.metrics" },
    { tag: "generatePolicyNumber.lockUntilBound" },
    { tag: "offlineBanner" },
    {
      tag: "submissionStatus.editable",
      priority: 100,
      displayName: "submissionStatus.editable",
    },
    {
      tag: "input.fx_rates",
      priority: 100,
      displayName: "input.fx_rates",
    },
    {
      tag: "input.cat_exposures",
      priority: 100,
      displayName: "input.cat_exposures",
    },
    {
      tag: "input.coverages",
      priority: 100,
      displayName: "input.coverages",
    },
    { tag: "<ActiveLayerEditor>.includeSir" },
    {
      tag: "<ActiveLayerEditor>.aggregateLimit",
      priority: 100,
      displayName: "<ActiveLayerEditor>.aggregateLimit",
    },
    {
      tag: "<ActiveLayerEditor>.TRIAAmount",
      priority: 100,
      displayName: "<ActiveLayerEditor>.TRIAAmount",
    },
    {
      tag: "<ActiveLayerEditor>.TRIA",
      priority: 100,
      displayName: "<ActiveLayerEditor>.TRIA",
    },
    {
      tag: "<ActiveLayerEditor>.shareOfPremiumVisible",
      priority: 100,
      displayName: "<ActiveLayerEditor>.shareOfPremiumVisible",
    },
    {
      tag: "<ActiveLayerEditor>.shareOfPremiumWithTRIA",
      priority: 100,
      displayName: "<ActiveLayerEditor>.shareOfPremiumWithTRIA",
    },
    { tag: "<ActiveLayerEditor>.puniWrap" },
    { tag: "<ActiveLayerEditor>.regionalCoverage" },
    { tag: "<ActiveLayerEditor>.perilsCoverage" },
    { tag: "<ActiveLayerEditor>.businessUnit" },
    { tag: "<ActiveLayerEditor>.isBinderIssued" },
    { tag: "<ActiveLayerEditor>.isPolicyIssued" },
    { tag: "<ActiveLayerEditor>.minimumEarnedPremium" },
    {
      tag: "inputs.lossDevelopment.exAnte",
      priority: 100,
      displayName: "inputs.lossDevelopment.exAnte",
    },
    {
      tag: "<Attachments>.rootFolder",
      priority: 100,
      displayName: "<Attachments>.rootFolder",
    },
    { tag: "<SOV>.heatmap" },
    { tag: "<Templates>.documentationNote" },
    { tag: "<Templates>.documentsIssued" },
    { tag: "<Templates>.peerReview" },
    { tag: "<Templates>.endorsements" },
    { tag: "<DocumentsIssued>.binder" },
    { tag: "<DocumentsIssued>.policy" },
    { tag: "<DocumentsIssued>.puniBinder" },
    { tag: "<DocumentsIssued>.puniPolicy" },
    {
      tag: "input.attachment.sic",
      priority: 200,
      displayName: "RRRe > SIC",
      tooltip:
        "Allows the importing of a specific type of file called a SIC which is only used in the RRRe pricing engine at the moment.",
    },
    {
      tag: "input.attachment.lossRun",
      priority: 200,
      displayName: "Insurance > Attachment Page Loss Run Button",
    },
    {
      tag: "input.claims.selfInsuredRetentions",
      priority: 100,
      displayName: "input.claims.selfInsuredRetentions",
    },
    {
      tag: "input.claims.claimsAndDeltaChart",
      priority: 100,
      displayName: "input.claims.claimsAndDeltaChart",
    },
    {
      tag: "input.claims.claimsDeltaChart",
      priority: 100,
      displayName: "input.claims.claimsDeltaChart",
    },
    {
      tag: "input.claims.claimsComparisonTable",
      priority: 100,
      displayName: "input.claims.claimsComparisonTable",
    },
    {
      tag: "input.claims.claimsLargest",
      priority: 100,
      displayName: "input.claims.claimsLargest",
    },
    {
      tag: "input.claims.inflationModelSelector",
      priority: 200,
      displayName: "Ark > Inflation Model Selector",
    },
    {
      tag: "input.endorsements.endorsementSelector",
      priority: 100,
      displayName: "input.endorsements.endorsementSelector",
    },
    {
      tag: "input.endorsements.subjectivities",
      priority: 100,
      displayName: "input.endorsements.subjectivities",
    },
    {
      tag: "input.endorsements.specialConditions",
      priority: 100,
      displayName: "input.endorsements.specialConditions",
    },
    {
      tag: "input.exposure.revenueRequired",
      priority: 100,
      displayName: "input.exposure.revenueRequired",
    },
    {
      tag: "input.program.programTiv",
      priority: 100,
    },
    {
      tag: "input.program.riskSummary",
      priority: 100,
      displayName: "input.program.riskSummary",
    },
    {
      tag: "input.program.insuredDetail",
      priority: 100,
      displayName: "input.program.insuredDetail",
    },
    {
      tag: "input.program.insuredDetail.insured",
      priority: 100,
      displayName: "input.program.insuredDetail.insured",
    },
    {
      tag: "input.program.insuredDetail.riskDescription",
      priority: 100,
      displayName: "input.program.insuredDetail.riskDescription",
    },
    {
      tag: "input.program.insuredDetail.insuredAddress",
      priority: 100,
      displayName: "input.program.insuredDetail.insuredAddress",
    },
    {
      tag: "input.program.insuredDetail.inception",
      priority: 100,
      displayName: "input.program.insuredDetail.inception",
    },
    {
      tag: "input.program.insuredDetail.expiration",
      priority: 100,
      displayName: "input.program.insuredDetail.expiration",
    },
    { tag: "input.program.insuredDetail.transaction" },
    { tag: "input.program.insuredDetail.effectiveDate" },
    { tag: "input.program.insuredDetail.dateReceived" },
    { tag: "input.program.insuredDetail.quoteDeadline" },
    { tag: "input.program.insuredDetail.quoteDate" },
    { tag: "input.program.insuredDetail.quoteValidUntil" },
    { tag: "input.program.insuredDetail.status" },
    {
      tag: "input.program.insuredDetail.proRata",
      priority: 100,
      displayName: "input.program.insuredDetail.proRata",
    },
    {
      tag: "input.program.insuredDetail.reference",
      priority: 100,
      displayName: "input.program.insuredDetail.reference",
    },
    { tag: "input.program.insuredDetail.quoteReference" },
    {
      tag: "input.program.insuredDetail.preferredUnderwriter",
      displayName: "input.program.insuredDetail.preferredUnderwriter",
      priority: 100,
    },
    { tag: "input.program.insuredDetail.secondaryUnderwriter" },
    {
      tag: "input.program.insuredDetail.me-underwriter",
      priority: 100,
      displayName: "input.program.insuredDetail.me-underwriter",
    },
    {
      tag: "input.program.insuredDetail.requestedBrokerage",
      priority: 100,
      displayName: "input.program.insuredDetail.requestedBrokerage",
    },
    {
      tag: "input.program.stateOfFiling",
      priority: 100,
      displayName: "input.program.stateOfFiling",
    },

    {
      tag: "input.program.insuredDetail.brokerage",
      priority: 100,
      displayName: "input.program.insuredDetail.brokerage",
    },
    {
      tag: "input.program.insuredDetail.oac",
      priority: 100,
      displayName: "input.program.insuredDetail.oac",
    },
    {
      tag: "input.program.insuredDetail.targetProfit",
      priority: 100,
      displayName: "input.program.insuredDetail.targetProfit",
    },
    {
      tag: "input.program.insuredDetail.gnulr",
      priority: 100,
      displayName: "input.program.insuredDetail.gnulr",
    },
    {
      tag: "input.program.insuredDetail.expenses",
      priority: 100,
      displayName: "input.program.insuredDetail.expenses",
    },
    {
      tag: "input.program.insuredDetail.paymentTerms",
      priority: 100,
      displayName: "input.program.insuredDetail.paymentTerms",
    },
    {
      tag: "input.program.insuredDetail.naicsForPricing",
      priority: 100,
      displayName: "input.program.insuredDetail.naicsForPricing",
    },
    {
      tag: "input.program.insuredDetail.naicsOfCompany",
      priority: 100,
      displayName: "input.program.insuredDetail.naicsOfCompany",
    },
    {
      tag: "input.program.insuredDetail.inUsLocation",
      priority: 100,
      displayName: "input.program.insuredDetail.inUsLocation",
    },
    {
      tag: "input.program.insuredDetail.turnoverCategory",
      priority: 100,
      displayName: "input.program.insuredDetail.turnoverCategory",
    },
    {
      tag: "input.program.insuredDetail.currency",
      priority: 100,
      displayName: "input.program.insuredDetail.currency",
    },
    {
      tag: "input.program.insuredDetail.reinsurance",
      priority: 100,
      displayName: "input.program.insuredDetail.reinsurance",
    },
    {
      tag: "input.program.insuredDetail.defaultPaperProvider",
    },
    {
      tag: "input.program.industryClass",
      priority: 100,
      displayName: "input.program.industryClass",
    },
    {
      tag: "input.program.modelSelector",
      priority: 100,
      displayName: "input.program.modelSelector",
    },
    {
      tag: "input.program.categoryCode",
      priority: 100,
      displayName: "input.program.categoryCode",
    },
    {
      tag: "input.program.broker",
      priority: 100,
      displayName: "input.program.broker",
    },
    { tag: "input.program.puniBroker" },
    { tag: "input.program.retailBroker" },
    {
      tag: "input.program.surplusBroker",
      priority: 100,
      displayName: "input.program.surplusBroker",
    },
    {
      tag: "input.program.duns",
      priority: 100,
      displayName: "input.program.duns",
    },
    {
      tag: "input.program.share",
      priority: 100,
      displayName: "input.program.share",
    },
    {
      tag: "input.program.renewal",
      priority: 100,
      displayName: "input.program.renewal",
    },
    {
      tag: "input.program.datascore",
      priority: 100,
      displayName: "input.program.datascore",
    },
    {
      tag: "input.program.reassured",
      priority: 100,
      displayName: "input.program.reassured",
    },
    {
      tag: "input.program.tower",
      priority: 100,
      displayName: "input.program.tower",
    },
    {
      tag: "input.program.selfInsuredRetentions",
      priority: 100,
      displayName: "input.program.selfInsuredRetentions",
    },
    {
      tag: "input.program.layerInput",
      priority: 100,
      displayName: "input.program.layerInput",
    },
    {
      tag: "input.program.notes",
      priority: 100,
      displayName: "input.program.notes",
    },
    { tag: "input.program.documentsIssued" },
    {
      tag: "input.scenarios.levelledScenarios",
      priority: 100,
      displayName: "input.scenarios.levelledScenarios",
    },
    {
      tag: "input.scenarios.valuedScenarios",
      priority: 100,
      displayName: "input.scenarios.valuedScenarios",
    },
    {
      tag: "input.scenarios.notes",
      priority: 100,
      displayName: "input.scenarios.notes",
    },
    { tag: "<Search>.transactionChip" },
    {
      tag: "<ActiveLayerEditor>.shareOfPremium",
      priority: 100,
      displayName: "<ActiveLayerEditor>.shareOfPremium",
    },
    {
      tag: "<ActiveLayerEditor>.slip-fee",
      priority: 100,
      displayName: "<ActiveLayerEditor>.slip-fee",
    },
    { tag: "<ActiveLayerEditor>.facPct" },
    { tag: "<ActiveLayerEditor>.facPremium" },
    {
      tag: "<ActiveLayerEditor>.target-profit",
      priority: 100,
      displayName: "<ActiveLayerEditor>.target-profit",
    },
    {
      tag: "<ActiveLayerEditor>.gnulr",
      priority: 200,
      displayName: "Ark > GNULR",
    },
    {
      tag: "<ActiveLayerEditor>.reinsurance",
      priority: 200,
      displayName: "<ActiveLayerEditor>.reinsurance",
    },
    {
      tag: "<ActiveLayerEditor>.policyType",
      priority: 200,
      displayName: "<ActiveLayerEditor>.policyType",
    },
    { tag: "<ActiveLayerEditor>.followedPolicy" },
    {
      tag: "<ActiveLayerEditor>.paymentTerms",
      priority: 200,
      displayName: "Helix > Payment Terms",
    },
    {
      tag: "<ActiveLayerEditor>.reference.generatePolicyNumber",
      priority: 200,
      displayName: "<ActiveLayerEditor>.reference.generatePolicyNumber",
    },
    {
      tag: "<ActiveLayerEditor>.puniReference",
      priority: 200,
    },
    { tag: "<ActiveSubmission>.status" },
    {
      tag: "<ActiveSubmission>.copyProgramId",
      priority: 100,
      displayName: "<ActiveSubmission>.copyProgramId",
    },
    {
      tag: "<ActiveSubmission>.source",
      priority: 100,
      displayName: "<ActiveSubmission>.source",
    },
    {
      tag: "pricing.modeling",
      priority: 200,
    },
    {
      tag: "pricing.lossFrequency",
      priority: 200,
      displayName: "Ark > Loss Frequency Tab",
    },
    {
      tag: "summary.print.layers.status",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Status' Column",
    },
    {
      tag: "summary.print.layers.ourLimit",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Our Limit' Column",
    },
    {
      tag: "summary.print.layers.partOf",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Part Of' Column",
    },
    {
      tag: "summary.print.layers.excess",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Excess' Column",
    },
    {
      tag: "summary.print.layers.premium",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Premium' Column",
    },
    {
      tag: "summary.print.layers.rpm",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'RPM' Column",
    },
    {
      tag: "summary.print.layers.ourGross",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Our Gross' Column",
    },
    {
      tag: "summary.print.layers.ourNet",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Our Net' Column",
    },
    {
      tag: "summary.print.layers.tac",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'TAC' Column",
    },
    {
      tag: "summary.print.layers.tp",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'TP' Column",
    },
    {
      tag: "summary.print.layers.gnulr",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'GNULR' Column",
    },
    {
      tag: "summary.print.layers.brokerage",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Brokerage' Column",
    },
    {
      tag: "summary.print.layers.slipFee",
      priority: 100,
      displayName: "Summary Print Layer Table: Toggle 'Slip Fee' Column",
    },
    {
      tag: "pricing.dnfLayerPricing",
      priority: 100,
    },
    { tag: "pricing.layerPricing.updateLossFrequency" },
    {
      tag: "pricing.layerPricing.baseLayer",
      priority: 100,
      displayName: "pricing.layerPricing.baseLayer",
    },
    {
      tag: "pricing.layerPricing.premiumAdjustments",
      priority: 100,
      displayName: "pricing.layerPricing.premiumAdjustments",
    },
    { tag: "pricing.layerPricing.documentsIssued" },
    {
      tag: "pricing.primaries.primariesTower",
      priority: 100,
      displayName: "pricing.primaries.primariesTower",
    },
    {
      tag: "<InsuredAverages>.update",
      priority: 100,
      displayName: "<InsuredAverages>.update",
    },
    {
      tag: "<RateChange>.summaryTable",
      priority: 100,
      displayName: "<RateChange>.summaryTable",
    },
    {
      tag: "<RateChange>.oldSummaryTable",
      priority: 100,
      displayName: "<RateChange>.oldSummaryTable",
    },
    {
      tag: "<RateChange>.notes",
      priority: 100,
      displayName: "<RateChange>.notes",
    },
    {
      tag: "<RateChange>.sideBySideClaimsChart",
      priority: 100,
      displayName: "<RateChange>.sideBySideClaimsChart",
    },
    {
      tag: "<RateChange>.claimsDeltaChart",
      priority: 100,
      displayName: "<RateChange>.claimsDeltaChart",
    },
    { tag: "<RateChange>.rebase" },
    {
      tag: "pricing.towerPricing.SIR",
      priority: 100,
      displayName: "pricing.towerPricing.SIR",
    },
    {
      tag: "pricing.towerPricing.calculate",
      priority: 100,
      displayName: "pricing.towerPricing.calculate",
    },
    {
      tag: "pricing.towerPricing.toggleNet",
    },
    { tag: "pricing.towerPricing.premiumChange" },
    { tag: "pricing.towerPricing.premiumChangePercent" },
    {
      tag: "<BrokerCreation>/name",
      priority: 99,
      displayName: "Create Broker > Name",
    },
    {
      tag: "<BrokerCreation>/email",
      priority: 99,
      displayName: "Create Broker > Email",
    },
    {
      tag: "<BrokerCreation>/workNumber",
      priority: 99,
      displayName: "Create Broker > Work Number",
    },
    {
      tag: "<BrokerCreation>/mobileNumber",
      priority: 99,
      displayName: "Create Broker > Mobile Number",
    },
    {
      tag: "<BrokerCreation>/company",
      priority: 99,
      displayName: "Create Broker > Broking House",
    },
    {
      tag: "<BrokerCreation>/address",
      priority: 99,
      displayName: "Create Broker > Address",
    },
    {
      tag: "<BrokerCreation>/location",
      priority: 99,
      displayName: "Create Broker > Location",
    },
    {
      tag: "pricing.towerPricing.asIfLayer",
      priority: 100,
      displayName: "pricing.towerPricing.asIfLayer",
    },
    { tag: "pricing.towerPricing.share.shareOfLimit" },
    { tag: "pricing.towerPricing.share.share" },
    { tag: "pricing.towerPricing.share.shareOfGwp" },
    { tag: "pricing.towerPricing.share.brokerage" },
    { tag: "pricing.towerPricing.share.shareOfNwp" },
    { tag: "pricing.towerPricing.share.comments" },
    { tag: "pricing.towerPricing.share.reference" },
    { tag: "pricing.towerPricing.share.coverage" },
    { tag: "pricing.towerPricing.share.policyForm" },
    { tag: "pricing.towerPricing.share.aggregateLimit" },
    { tag: "pricing.towerPricing.share.plAggregateLimit" },
    { tag: "summary.peerReview.peerReviewer" },
    { tag: "summary.peerReview.attendees" },
    {
      tag: "summary.peerReview.me-reviewer",
      priority: 100,
      displayName: "summary.peerReview.me-reviewer",
    },
    {
      tag: "summary.notes.peerReview.email",
      priority: 100,
      displayName: "summary.notes.peerReview.email",
    },
    {
      tag: "summary.notes.peerReview.complete",
      priority: 100,
      displayName: "summary.notes.peerReview.complete",
    },
    {
      tag: "summary.notes.peerReview",
      priority: 100,
      displayName: "summary.notes.peerReview",
    },
    {
      tag: "<Print>.peerReview",
      priority: 100,
      displayName: "<Print>.peerReview",
    },
    {
      tag: "<Print>.claimsCharts",
      priority: 100,
      displayName: "<Print>.claimsCharts",
    },
    {
      tag: "<Print>.topClaims",
      priority: 100,
      displayName: "<Print>.topClaims",
    },
    {
      tag: "<Print>.lossFrequencyChart",
      priority: 100,
      displayName: "<Print>.lossFrequencyChart",
    },
    {
      tag: "<Print>.tower",
      priority: 100,
      displayName: "<Print>.tower",
    },
    {
      tag: "<Print>.peerPricing",
      priority: 100,
      displayName: "<Print>.peerPricing",
    },
    { tag: "<Print>.summary.priorGwp" },
    { tag: "<Print>.uwJudgments" },
    { tag: "<Print>.exposureFlags" },
    {
      tag: "input.program",
      priority: 440,
      displayName: "Tab > Input > Program",
    },
    { tag: "input.claims", priority: 440, displayName: "Tab > Input > Claims" },
    { tag: "input.sic", priority: 440, displayName: "Tab > Input > SIC" },
    {
      tag: "input.prem-deduct",
      priority: 440,
      displayName: "Tab > Input > Premium Deduction",
    },
    {
      tag: "input.sov",
      priority: 440,
      displayName: "Tab > Input > SOV",
    },
    {
      tag: "input.loss-development",
      priority: 440,
      displayName: "Tab > Input > Loss Development",
    },
    {
      tag: "input.exposure",
      priority: 440,
      displayName: "Tab > Input > Exposure",
    },
    { tag: "input.flags", priority: 440, displayName: "Tab > Input > Flags" },
    {
      tag: "input.endorsements",
      priority: 440,
      displayName: "Tab > Input > Endorsements",
    },
    {
      tag: "input.scenarios",
      priority: 440,
      displayName: "Tab > Input > Scenarios",
    },
    {
      tag: "input.attachments",
      priority: 440,
      displayName: "Tab > Input > Attachments",
    },
    {
      tag: "input.pricing_assumptions",
      priority: 440,
      displayName: "Tab > Input > Pricing Assumptions",
    },
    {
      tag: "<AppMenu>/scenarios",
      priority: 999,
      displayName: "App Menu > Scenarios",
    },
    {
      tag: "<AppMenu>/reports",
      priority: 998,
      displayName: "App Menu > Reports",
    },
    { tag: "inputs", priority: 800, displayName: "Section > Inputs" },
    { tag: "pricing", priority: 800, displayName: "Section > Pricing" },
    { tag: "comparison", priority: 800, displayName: "Section > Comparison" },
    { tag: "summary", priority: 800, displayName: "Section > Summary" },
    {
      tag: "comparison.review",
      priority: 432,
      displayName: "Tab > Comparison > Review",
    },
    {
      tag: "comparison.group",
      priority: 431,
      displayName: "Tab > Comparison > Group",
    },
    {
      tag: "comparison.exposure",
      priority: 430,
      displayName: "Tab > Comparison > Exposure",
    },
    {
      tag: "pricing.tailAnalysis",
      priority: 420,
      displayName: "Tab > Pricing > Tail Analysis",
    },
    {
      tag: "pricing.claimsSelector",
      priority: 420,
      displayName: "Tab > Pricing > Claims Selector",
    },
    {
      tag: "pricing.lossFrequency",
      priority: 420,
      displayName: "Tab > Pricing > Loss Frequency",
    },
    {
      tag: "pricing.burningCost",
      priority: 420,
      displayName: "Tab > Pricing > Burning Cost",
    },
    {
      tag: "pricing.baseLayerBurn",
      priority: 420,
      displayName: "Tab > Pricing > Base Layer Burn",
    },
    {
      tag: "pricing.layerPricing",
      priority: 420,
      displayName: "Tab > Pricing > Layer Pricing",
    },
    {
      tag: "pricing.towerPricing",
      priority: 420,
      displayName: "Tab > Pricing > Tower Pricing",
    },
    {
      tag: "pricing.expiringProgram",
      priority: 420,
      displayName: "Tab > Pricing > Expiring Program",
    },
    {
      tag: "pricing.ultimate",
      priority: 420,
      displayName: "Tab > Pricing > Ultimate",
    },
    {
      tag: "pricing.rateChange",
      priority: 420,
      displayName: "Tab > Pricing > Rate Change",
    },
    {
      tag: "pricing.claimsSelector.includedLossTypes",
      priority: 100,
      displayName: "Pricing > Claims Selector > Included Loss Types",
    },
    {
      tag: "pricing.towerPricing.addLayer",
      priority: 100,
      displayName: "Tower Pricing Table > Add Layer",
    },
    {
      tag: "pricing.towerPricing.deleteLayer",
      priority: 100,
      displayName: "Tower Pricing Table > Delete Layer",
    },
    {
      tag: "pricing.towerPricing.index",
      priority: 100,
      displayName: "Tower Pricing Table > Index",
    },
    {
      tag: "pricing.towerPricing.carrier",
      priority: 100,
      displayName: "Tower Pricing Table > Carrier",
    },
    {
      tag: "pricing.towerPricing.expandShare",
      priority: 100,
      displayName: "Tower Pricing Table > Expand Share",
    },
    {
      tag: "pricing.towerPricing.tertile",
      priority: 100,
      displayName: "Tower Pricing Table > Tertile",
    },
    {
      tag: "pricing.towerPricing.limit",
      priority: 100,
      displayName: "Tower Pricing Table > Limit",
    },
    {
      tag: "pricing.towerPricing.calculateError",
      priority: 100,
      displayName: "Tower Pricing Table > Calculate Error",
    },
    {
      tag: "pricing.towerPricing.attachment",
      priority: 100,
      displayName: "Tower Pricing Table > Attachment",
    },
    {
      tag: "pricing.towerPricing.grossPremium",
      priority: 100,
      displayName: "Tower Pricing Table > Gross Premium",
    },
    {
      tag: "pricing.towerPricing.brokerage",
      priority: 100,
      displayName: "Tower Pricing Table > Brokerage",
    },
    {
      tag: "pricing.towerPricing.netPremium",
      priority: 100,
      displayName: "Tower Pricing Table > Net Premium",
    },
    {
      tag: "pricing.towerPricing.grossRPM",
      priority: 100,
      displayName: "Tower Pricing Table > Gross RPM",
    },
    {
      tag: "pricing.towerPricing.netRPM",
      priority: 100,
      displayName: "Tower Pricing Table > Net RPM",
    },
    {
      tag: "pricing.towerPricing.grossAsIfRPM",
      priority: 100,
      displayName: "Tower Pricing Table > Gross As If RPM",
    },
    {
      tag: "pricing.towerPricing.grossAsIfTP",
      priority: 100,
      displayName: "Tower Pricing Table > Gross As If TP",
    },
    {
      tag: "pricing.towerPricing.selectLayer",
      priority: 100,
      displayName: "Tower Pricing Table > Select Layer",
    },
    {
      tag: "pricing.towerPricing.grossSelectedRPM",
      priority: 100,
      displayName: "Tower Pricing Table > Gross Selected RPM",
    },
    {
      tag: "pricing.towerPricing.netSelectedRPM",
      priority: 100,
      displayName: "Tower Pricing Table > Net Selected RPM",
    },
    {
      tag: "pricing.towerPricing.grossSelectedMargin",
      priority: 100,
      displayName: "Tower Pricing Table > Gross Selected Margin",
    },
    {
      tag: "pricing.towerPricing.grossModelledMargin",
      priority: 100,
      displayName: "Tower Pricing Table > Gross Modelled Margin",
    },
    {
      tag: "pricing.towerPricing.grossRelativeRate",
      priority: 100,
      displayName: "Tower Pricing Table > Gross Relative Rate",
    },
    {
      tag: "pricing.towerPricing.netRelativeRate",
      priority: 100,
      displayName: "Tower Pricing Table > Net Relative Rate",
    },
    { tag: "pricing.towerPricing.inception" },
    { tag: "pricing.towerPricing.expiration" },
    {
      tag: "summary.program",
      priority: 410,
      displayName: "Tab > Summary > Program",
    },
    {
      tag: "summary.notes",
      priority: 410,
      displayName: "Tab > Summary > Notes",
    },
    {
      tag: "summary.snapshots",
      priority: 410,
      displayName: "Tab > Summary > Snapshots",
    },
    {
      tag: "summary.templates",
      priority: 410,
      displayName: "Tab > Summary > Templates",
    },
    { tag: "summary.allocations" },
    { tag: "<Submission>.duplicate" },
    {
      tag: "<Submission>.comments",
      priority: 500,
      displayName: "<Submission>.comments",
    },
    { tag: "<Submission>.allocation" },
    { tag: "<Submission>.meetingNotes" },
    {
      tag: "<Print>.tower.tower",
      priority: 100,
      displayName: "<Print>.tower.tower",
    },
    {
      tag: "<Print>.tower.pricing",
      priority: 100,
      displayName: "<Print>.tower.pricing",
    },
    {
      tag: NOT_SAVED_INTER_PAGE_WARNING,
      priority: 100,
      displayName: NOT_SAVED_INTER_PAGE_WARNING,
    },
    {
      tag: NOT_SAVED_APP_EXIT_WARNING,
      priority: 100,
      displayName: NOT_SAVED_APP_EXIT_WARNING,
    },
    {
      tag: CREATE_CLAIMS_COVERAGE_LIST,
      priority: 100,
      displayName: CREATE_CLAIMS_COVERAGE_LIST,
    },
    {
      tag: APP_BAR_ISSUE_FEEDBACK,
      priority: 100,
      displayName: APP_BAR_ISSUE_FEEDBACK,
    },
    {
      tag: SUB_STAT_REPORT_SHOW_LAYER_REFERNCE,
      priority: 100,
      displayName: SUB_STAT_REPORT_SHOW_LAYER_REFERNCE,
    },
    {
      tag: SUB_STAT_REPORT_SHOW_PROGRAM_REFERENCE,
      priority: 100,
      displayName: SUB_STAT_REPORT_SHOW_PROGRAM_REFERENCE,
    },
    {
      tag: SOV_DOWNLOAD_BUTTON_ENABLED,
      priority: 100,
      displayName: SOV_DOWNLOAD_BUTTON_ENABLED,
    },
  ],
  feature: [
    {
      flag: "claims/useColumnMetaSuggestions",
      displayName: "claims/useColumnMetaSuggestions",
      created: "2024-05-02",
    },
    {
      flag: "ui/useAsyncClaimsTransform",
      displayName: "ui/useAsyncClaimsTransform",
      created: "2024-02-25",
    },
    {
      flag: "submissions/allow-list-different-to-read",
      displayName: "Submissions: Allow list different to read",
      created: "2024-03-06",
    },
    {
      flag: "submissions/use-url-transport",
      created: "2024-09-01",
    },
    {
      flag: UPLOAD_CLAIMS_LOSS_FILES_VIA_FILES_SERVICE,
      displayName: UPLOAD_CLAIMS_LOSS_FILES_VIA_FILES_SERVICE,
      created: "2024-03-01",
    },
    {
      flag: "claims/applyFxRates",
      displayName: "claims/applyFxRates",
      created: "2024-04-01",
    },
    {
      flag: REVIEW_REPORTS_SHOW_ALL_VALUES_IN_FILTERS,
      created: "2024-05-07",
      displayName: REVIEW_REPORTS_SHOW_ALL_VALUES_IN_FILTERS,
    },
    {
      flag: "ui/useTransferService",
      created: "2024-06-10",
    },
    {
      flag: "ui/legacy.search",
      displayName: "Enables the legacy search screen",
      created: "2024-06-19",
    },
    {
      flag: DYNAMIC_AUTO_COMPLETE,
      created: "2024-06-19",
    },
    {
      flag: "files/disableOleObjectExtraction",
      created: "2024-06-20",
    },
    {
      flag: "ui/disableSchemaVersionValidation",
      created: "2024-06-19",
    },
    {
      flag: "ui/useClaimsV2Frontend",
      created: "2024-09-13",
    },
    {
      flag: "ui/submissionReloadOnSave",
      created: "2024-09-20",
    },
    {
      flag: "ui/pasteExposureRow",
      created: "2024-10-09",
    },
    {
      flag: "ui/enableEditOfClaimsOpenClosed",
      created: "2024-10-10",
    },
    {
      flag: "ui/disableSpellcheck",
      created: "2024-11-27",
    },
  ],
};

export default flags;
