import AuthenticatedApp from "./components/AuthenticatedApp";
import ErrorBoundary from "./components/ErrorBoundary";
import { loadMaintenanceStatus } from "./store/actions/temp/maintenance/maintenanceStatusActions";
import "./styles/styles.scss";
import { ThemeProvider } from "@mui/material/styles";
import theme from "components/common/Theme";
import { getMaintenanceControlUrl } from "config";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    loadMaintenanceStatus(getMaintenanceControlUrl())(dispatch);
  });
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <BrowserRouter>
          <AuthenticatedApp />
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
