import { GetApp } from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Typography,
  Tooltip,
  Grid,
} from "@mui/material";
import * as dates from "common/dates";
import * as numbers from "common/numbers";
import { flattenKeys } from "common/objects";
import Component from "components/Component";
import Button from "components/common/Button";
import DefaultLoader from "components/common/DefaultLoader";
import DocumentsIssued from "components/common/DocumentsIssued";
import PeerReviewNotes from "components/common/PeerReviewNotes";
import TextNote from "components/common/TextNote";
import Visible from "components/common/Visible";
import Endorsements from "components/inputs/endorsements/Endorsements";
import { LayerSelector } from "components/layers/ActiveLayerSelector";
import * as config from "config";
import moment from "moment";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import * as docGenService from "services/docGenService";
import * as peopleSelectors from "store/selectors/people/peopleSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as submissionSelectors from "store/selectors/submission/submissionSelector";
import * as userSelectors from "store/selectors/user/userSelectors";

const BUTTON_WIDTH = 250;

const OUR_LAYER_KEYS = new Set([
  "aggregateLimit",
  "facPremium",
  "grossPremium",
  "limit",
  "lineSize",
  "netPuniWrap",
  "puniWrap",
  "puniWrapBrokerage",
]);

const fillFilename = ({ filename, values }) => {
  if (filename == null) {
    return filename;
  }
  return filename.replace(/{([^}]+)}/g, (match, key) => {
    return typeof values[key] !== "undefined" ? values[key] : match;
  });
};

const useDownloadTemplate = ({ layerIndex }) => {
  const [
    fillTemplate,
    { isLoading: isDownloading, isError },
  ] = docGenService.useFillTemplateMutation();

  const resolvedSubmission = useSelector(
    submissionSelectors.selectResolvedSubmissionWithoutInput
  );

  const underwriter = useSelector(peopleSelectors.selectPreferredUnderwriter);
  const broker = useSelector(peopleSelectors.selectBroker);

  const makeJinjaContent = () => ({
    submission: resolvedSubmission,
    layerId: resolvedSubmission.layers[layerIndex].id,
  });

  const makeContent = () => {
    const formatDate = (format) => (date) =>
      date ? moment.parseZone(date).format(format) : "";

    const program = {
      ...resolvedSubmission.program,
      brokerHouse: broker?.company,
      broker: broker?.name,
      brokerContact: broker?.email,
      brokerId: broker?.id,
      preferredUnderwriter: underwriter?.name,
      preferredUnderwriterId: underwriter?.id,
      inceptionYear: formatDate("yyyy")(resolvedSubmission?.program?.inception),
    };
    const layer = resolvedSubmission.layers[layerIndex];
    const ourLayer = Number.isFinite(layer?.shareOfLine)
      ? Object.fromEntries(
          Object.entries(layer)
            .filter(([k, v]) => OUR_LAYER_KEYS.has(k) && Number.isFinite(v))
            .map(([k, v]) => [k, v * layer.shareOfLine * 0.01])
        )
      : {};

    const formatDnfDates = formatDate("DD-MMMM-yyyy");

    return flattenKeys({
      program,
      layers: resolvedSubmission.layers,
      layer,
      ourLayer,
      tower: resolvedSubmission.tower?.layers,
      dnf: {
        today: formatDnfDates(dates.getDate()),
        inception: formatDnfDates(program.inception),
        expiration: formatDnfDates(program.expiration),
        perils: "",
        ourLimit: numbers.currencyFormat(ourLayer.limit),
        ourGrossPremium: numbers.currencyFormat(ourLayer.grossPremium),
        ourTriaAmount: numbers.currencyFormat(layer.TRIAAmount),
        limit: numbers.currencyFormat(layer.limit),
        attachment: numbers.currencyFormat(layer.attachment),
        grossPremium: numbers.currencyFormat(layer.grossPremium),
        triaAmount:
          layer.TRIAAmount != null && layer.shareOfLine
            ? numbers.currencyFormat(
                layer.TRIAAmount / (layer.shareOfLine / 100)
              )
            : "",
        tiv: numbers.currencyFormat(resolvedSubmission.programPricing?.TIV),
      },
    });
  };

  const downloadTemplate = async ({ template, emailTargetId = null }) => {
    const content = makeContent();
    const filename = fillFilename({
      filename: template.filename,
      values: content,
    });
    return fillTemplate({
      templateId: template.id,
      templateForm: template.form,
      content: template.form === "WORD_JINJA" ? makeJinjaContent() : content,
      emailTargetId,
      filename,
    });
  };

  return { downloadTemplate, isDownloading, isError };
};

const DownloadTemplate = ({ layerIndex, template }) => {
  const { downloadTemplate, isDownloading, isError } = useDownloadTemplate({
    layerIndex,
  });
  return (
    <Button
      sx={{ minWidth: BUTTON_WIDTH }}
      onClick={() =>
        downloadTemplate({
          template,
        })
      }
      startIcon={<GetApp />}
      isLoading={isDownloading}
      hasError={isError}
    >
      {"Fill Template"}
    </Button>
  );
};

const EmailTemplate = ({ layerIndex, template, emailTarget }) => {
  const { downloadTemplate, isDownloading, isError } = useDownloadTemplate({
    layerIndex,
  });
  return (
    <Tooltip
      title={
        <div>
          {`To: ${emailTarget?.toEmails?.join(", ")}`}
          <br />
          {`Cc: ${emailTarget?.ccEmails?.join(", ")}`}
        </div>
      }
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        spacing={2}
      >
        <Typography fontWeight={"bold"}>{`${emailTarget?.name}: `}</Typography>
        <Button
          sx={{ minWidth: BUTTON_WIDTH }}
          onClick={() =>
            downloadTemplate({
              template,
              emailTargetId: emailTarget.id,
            })
          }
          startIcon={<GetApp />}
          isLoading={isDownloading}
          hasError={isError}
        >
          {"Fill & Email Template"}
        </Button>
      </Stack>
    </Tooltip>
  );
};

const Templates = () => {
  const activeLayer = useSelector(pricingSelectors.getActiveLayerIndex);
  const layers = useSelector(pricingSelectors.getTower);

  const isDocumentsIssuedVisible = useSelector(userSelectors.selectIsVisible)(
    "<Templates>.documentsIssued"
  );

  const [layerIndex, setLayerIndex] = useState(activeLayer);

  const {
    data: templates,
    isFetching: isFetchingTemplates,
  } = docGenService.useListTemplatesQuery();

  return (
    <>
      <Grid item container spacing={config.GRID_SPACING}>
        <Grid item container xs={isDocumentsIssuedVisible ? 9 : 12}>
          <LayerSelector
            includeAddLayerButton={false}
            includeSummaryRow={false}
            includedColumnKeys={[
              "statusBadge",
              "shareOfLimit",
              "limit",
              "attachment",
              "grossPremium",
              "grossPPM",
            ]}
            updateActive={setLayerIndex}
            selectedIndex={layerIndex}
            layers={layers}
          />
        </Grid>
        <Visible byTag={"<Templates>.documentsIssued"} defaultVisible={false}>
          <Grid item container xs={3}>
            <DocumentsIssued layer={layers?.[layerIndex]} />
          </Grid>
        </Visible>
      </Grid>
      <Visible byTag={"<Templates>.documentationNote"} defaultVisible={false}>
        <TextNote noteKey={"documentation"} title={"Notes"} />
      </Visible>
      <Visible byTag={"<Templates>.peerReview"} defaultVisible={false}>
        <PeerReviewNotes
          title={"Peer Review"}
          lg_width={12}
          growVertically={false}
        />
      </Visible>
      <Visible byTag={"<Templates>.endorsements"} defaultVisible={false}>
        <Endorsements />
      </Visible>
      <Component title={"Templates"}>
        {isFetchingTemplates ? (
          <DefaultLoader
            color={"#dc7f4c"}
            size={60}
            style={{
              display: "block",
              textAlign: "center",
            }}
          />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates?.map((template) => (
                <TableRow key={template.id}>
                  <TableCell sx={{ verticalAlign: "top" }}>
                    <Typography variant={"h6"}>{template.title}</Typography>
                  </TableCell>
                  <TableCell align={"right"}>
                    <Stack
                      direction={"column"}
                      alignItems={"flex-end"}
                      justifyContent={"flex-start"}
                      spacing={2}
                    >
                      <DownloadTemplate
                        layerIndex={layerIndex}
                        template={template}
                      />
                      {template.allowedEmailTargets?.map((emailTarget) => (
                        <Fragment key={emailTarget.id}>
                          <hr width={2 * BUTTON_WIDTH} color={"#e3e3e3"} />
                          <EmailTemplate
                            layerIndex={layerIndex}
                            template={template}
                            emailTarget={emailTarget}
                          />
                        </Fragment>
                      ))}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Component>
    </>
  );
};

export default Templates;
