import SOV from "./SOV/SOV";
import CatExposures from "./catExposures/CatExposures";
import LossDevelopment from "./lossDevelopment/LossDevelopment";
import * as navigationSupport from "components/common/Navigation.support";
import PricingAssumptions from "components/inputs/Pricing/PricingAssumptions";
import Attachments from "components/inputs/attachments/Attachments";
import Claims from "components/inputs/claims/Claims";
import Coverages from "components/inputs/coverage/Coverages";
import Currencies from "components/inputs/currency/Currencies";
import Endorsements from "components/inputs/endorsements/Endorsements";
import Exposure from "components/inputs/exposure/Exposure";
import Flags from "components/inputs/flags/Flags";
import PremDeduct from "components/inputs/premDeduct/PremDeduct";
import Program from "components/inputs/program/Program";
import Scenarios from "components/inputs/scenarios/Scenarios";
import SIC from "components/inputs/sic/SIC";
import DashboardContent from "components/layout/DashboardContent";
import DashboardHeader from "components/layout/DashboardHeader";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import * as userSelectors from "store/selectors/user/userSelectors";

const Inputs = ({
  tabs,
  isFullWidth,
  hasStickyHeaders,
  navigationState,
  setNavigationState,
}) => {
  const {
    orderSubTabs,
    isSubTabOrderLoading,
  } = navigationSupport.useOrderSubTabs({ tabName: "Inputs" });

  if (isSubTabOrderLoading) {
    return <></>;
  }

  const orderedTabs = orderSubTabs(tabs);

  return (
    <>
      <DashboardHeader
        tabs={orderedTabs}
        parentPath={"inputs"}
        navigationState={navigationState}
        setNavigationState={setNavigationState}
        isFullWidth={isFullWidth}
        sticky={hasStickyHeaders}
      />
      <DashboardContent isFullWidth={isFullWidth}>
        <Routes>
          {orderedTabs.map(({ element, path }) => (
            <Route key={path} path={`${path}/*`} element={element} />
          ))}
          <Route
            path={"*"}
            element={
              <Navigate
                to={
                  navigationState?.inputs != null &&
                  orderedTabs.some((tab) => tab.path === navigationState.inputs)
                    ? navigationState.inputs
                    : orderedTabs[0].path
                }
                replace={true}
              />
            }
          />
        </Routes>
      </DashboardContent>
    </>
  );
};

const TABS = [
  {
    title: "Program",
    path: "program",
    element: <Program />,
    visibilityTag: "input.program",
    defaultVisibility: true,
  },
  {
    title: "Claims",
    path: "claims",
    element: <Claims />,
    visibilityTag: "input.claims",
    defaultVisibility: true,
  },
  {
    title: "SIC",
    path: "sic",
    element: <SIC />,
    visibilityTag: "input.sic",
    defaultVisibility: false,
  },
  {
    title: "Profile",
    path: "prem-deduct",
    element: <PremDeduct />,
    visibilityTag: "input.prem-deduct",
    defaultVisibility: false,
  },
  {
    title: "Loss Development",
    path: "loss-development",
    element: <LossDevelopment />,
    visibilityTag: "input.loss-development",
    defaultVisibility: false,
  },
  {
    title: "Exposure",
    path: "exposure",
    element: <Exposure />,
    visibilityTag: "input.exposure",
    defaultVisibility: true,
  },
  {
    title: "Flags",
    path: "flags",
    element: <Flags />,
    visibilityTag: "input.flags",
    defaultVisibility: true,
  },
  {
    title: "Endorsements",
    path: "endorsements",
    element: <Endorsements />,
    visibilityTag: "input.endorsements",
    defaultVisibility: false,
  },
  {
    title: "Scenarios",
    path: "scenarios",
    element: <Scenarios />,
    visibilityTag: "input.scenarios",
    defaultVisibility: true,
  },
  {
    title: "Attachments",
    path: "attachments",
    element: <Attachments />,
    visibilityTag: "input.attachments",
    defaultVisibility: true,
  },
  {
    title: "SOV",
    path: "sov",
    element: <SOV />,
    visibilityTag: "input.sov",
    defaultVisibility: false,
  },
  {
    title: "Coverages",
    path: "coverages",
    element: <Coverages />,
    visibilityTag: "input.coverages",
    defaultVisibility: false,
  },
  {
    title: "Cat Exposures",
    path: "cat-exposures",
    element: <CatExposures />,
    visibilityTag: "input.cat_exposures",
    defaultVisibility: false,
  },
  {
    title: "Pricing Assumptions",
    path: "pricing_assumptions",
    element: <PricingAssumptions />,
    visibilityTag: "input.pricing_assumptions",
    defaultVisibility: false,
  },
  {
    title: "FX Rates",
    path: "fx_rates",
    element: <Currencies />,
    visibilityTag: "input.fx_rates",
    defaultVisibility: false,
  },
];

export const mapStateToProps = (state) => {
  const userConfig = userSelectors.selectUserConfig(state);
  const stateVisibilitySelectors = userSelectors.getComponentOnStateVisibility(
    state
  );
  return {
    tabs: TABS.filter((tab) => {
      const tag = tab.visibilityTag;
      const stateIndependentVisibility =
        userConfig.componentVisibility?.[tag] ?? tab.defaultVisibility;
      const stateVisibilty =
        stateVisibilitySelectors?.[tag] ?? (() => stateIndependentVisibility);
      return stateVisibilty(state) ?? stateIndependentVisibility;
    }),
  };
};

export default connect(mapStateToProps)(Inputs);
