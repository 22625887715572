export const ATTACHMENT_UPLOADED = "ATTACHMENT_UPLOADED";
export const COPYING_ATTACHMENT_TO_LOSS_FILE =
  "COPYING_ATTACHMENT_TO_LOSS_FILE";
export const OPEN_ATTACHMENT_DIALOG = "OPEN_ATTACHMENT_DIALOG";
export const CLOSE_ATTACHMENT_DIALOG = "CLOSE_ATTACHMENT_DIALOG";
export const UPDATE_EMAIL_TIMER = "UPDATE_EMAIL_TIMER";
export const REMOVE_EXPIRED_EMAIL = "REMOVE_EXPIRED_EMAIL";

export const CREATE_DIRECTORY = "CREATE_ATT_DIRECTORY";
export const REMOVE_DIRECTORY = "REMOVE_ATT_DIRECTORY";
export const RENAME_DIRECTORY = "RENAME_ATT_DIRECTORY";
export const MOVE_DIRECTORY_TO_DIRECTORY = "MOVE_DIRECTORY_TO_ATT_DIRECTORY";
export const MOVE_FILE_TO_DIRECTORY = "MOVE_FILE_TO_ATT_DIRECTORY";
export const ADD_FILE_TO_DIRECTORY = "ADD_FILE_TO_ATT_DIRECTORY";
export const REMOVE_FILE_FROM_DIRECTORY = "REMOVE_FILE_FROM_ATT_DIRECTORY";
export const SET_NAME_OF_FILE_IN_DIRECTORY = "SET_NAME_OF_FILE_IN_DIRECTORY";
export const MOVE_FILE_TO_FILE = "MOVE_FILE_TO_FILE";
