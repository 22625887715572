import DownloadIcon from "@mui/icons-material/GetApp";
import Button from "components/common/Button";
import * as reportService from "services/reportService";

export const ReviewDownloadButtons = ({ name, type, filterArgs }) => {
  const [
    allTrigger,
    { isLoading: allLoading, isError: allErrored, error: allError },
  ] = reportService.useDownloadReportMutation();

  const [
    filteredTrigger,
    {
      isLoading: filteredLoading,
      isErrot: filteredErrored,
      error: filteredError,
    },
  ] = reportService.useDownloadReportMutation();

  const downloadFiltered = () => {
    filteredTrigger({
      type: type,
      args: filterArgs.args ?? {},
      format: "excel",
      filename: `Filtered${name}.xlsx`,
    });
  };

  const downloadAll = () => {
    allTrigger({
      type: type,
      args: { requiredColumns: filterArgs.args?.requiredColumns || null },
      format: "excel",
      filename: `Full${name}.xlsx`,
    });
  };

  return (
    <div>
      <Button
        onClick={downloadFiltered}
        isLoading={filteredLoading}
        hasError={filteredErrored}
        errorTooltip={filteredError}
        startIcon={<DownloadIcon />}
        sx={{ marginRight: "10px" }}
      >
        {"Download"}
      </Button>
      <Button
        onClick={downloadAll}
        isLoading={allLoading}
        hasError={allErrored}
        errorTooltip={allError}
        startIcon={<DownloadIcon />}
      >
        {"Download All"}
      </Button>
    </div>
  );
};
