import {
  DoneRounded as SuccessIcon,
  PriorityHighRounded as FailedIcon,
} from "@mui/icons-material";
import { Checkbox, TableCell, Tooltip, Stack } from "@mui/material";
import * as objects from "common/objects";
import DefaultLoader from "components/common/DefaultLoader";
import GoodBadTableCell from "components/common/GoodBadTableCell";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import StatusSelectV2 from "components/common/StatusSelectV2";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import * as submissionsService from "services/submissionsService";

export const compareStringFields = (readValue) => (a, b) => {
  const sanitise = (x) =>
    (readValue(x)?.trim()?.toLowerCase() || "zzzzz") + x.submissionId;
  return sanitise(a).localeCompare(sanitise(b));
};

export const compareNumericFields = (readValue) => (a, b) => {
  const compare = (readValue(a) ?? 0) - (readValue(b) ?? 0);
  return compare === 0
    ? (a.submissionId ?? "zzzzz").localeCompare(b.submissionId ?? "zzzzz")
    : compare;
};

export const invertComparatorIf = (isInverted) =>
  isInverted ? (f) => (a, b) => -1 * f(a, b) : (f) => f;

export const structureFlattenedData = (flattened) => {
  const bySubmission = {};
  for (const row of flattened ?? []) {
    bySubmission[row.submissionId] = {
      ...bySubmission[row.submissionId],
      ...row,
      layers: [...(bySubmission[row.submissionId]?.layers ?? []), row],
    };
  }
  return Object.values(bySubmission);
};

const applyAttributeFilters = (submissions, filters) => {
  const searchTerms = filters.filter((item) => item.value.length !== 0);

  if (searchTerms.length === 0) {
    return submissions;
  } else {
    return submissions.filter((x) => {
      let matchCount = 0;

      searchTerms.forEach((search) => {
        if (search.context === "Status") {
          for (let i = 0; i < x.layers.length; i++) {
            if (search.value.includes(x.layers[i].status)) {
              matchCount += 1;
              break;
            }
          }
        } else if (search.context === "Insured") {
          matchCount += search.value.includes(x.insured ?? "") ? 1 : 0;
        } else if (search.context === "Underwriter") {
          matchCount += search.value.includes(x.preferredUnderwriter ?? "")
            ? 1
            : 0;
        } else if (search.context === "Broker") {
          matchCount += search.value.includes(x.broker ?? "") ? 1 : 0;
        } else if (search.context === "Broking House") {
          matchCount += search.value.includes(x.brokerHouse ?? "") ? 1 : 0;
        } else if (search.context === "Industry Class") {
          matchCount += search.value.includes(x.industryClass ?? "") ? 1 : 0;
        }
      });

      return matchCount === searchTerms.length;
    });
  }
};

const applyDateFilter = (submissions, dateFilter) => {
  if (dateFilter?.from == null && dateFilter?.to == null) {
    return submissions;
  }
  return submissions.filter((x) =>
    dayjs(x?.inception).isBetween(
      dateFilter?.from ?? "1000-01-01",
      dateFilter?.to ?? dayjs(),
      "day",
      "[]"
    )
  );
};

export const filterLayers = (searchInput, searchableColumns) => {
  searchInput = (searchInput ?? "").trim().toLowerCase();
  if (!searchInput) {
    return (s) => s;
  }

  const searchStrings = searchInput.split(/\s+/);

  const makeSearchableLayerObject = ({ submission, layer }) =>
    Object.fromEntries(
      Object.entries({
        ...submission,
        ...layer,
      })
        .filter(
          ([k, v]) => searchableColumns.includes(k) && typeof v === "string"
        )
        .map(([k, v]) => [k, v.trim().toLowerCase()])
    );

  return (submission) => ({
    ...submission,
    layers: (submission.layers ?? []).filter(
      (layer) =>
        objects.calculateSearchScore(
          makeSearchableLayerObject({ submission, layer }),
          { searchStrings }
        ) > 0
    ),
  });
};

export const filterSubmissions = (
  submissions,
  searchInput,
  searchableColumns,
  filters,
  dateFilter
) => {
  submissions = submissions ?? [];
  const matchedByFilter = applyAttributeFilters(
    applyDateFilter(submissions, dateFilter),
    filters
  );
  return matchedByFilter
    .map(filterLayers(searchInput, searchableColumns))
    .filter((s) => s.layers?.length);
};

export const getColumnValues = (column, flatSubmissions) => {
  const colVals = flatSubmissions
    .map((row) => row[column])
    .map((v) => (!v ? "" : v));
  const unique = [...new Set(colVals)];
  return unique.map((uv) => ({ value: uv, label: uv === "" ? "[Blank]" : uv }));
};
export const getFilterItems = (
  context,
  submissions,
  searchInput,
  searchableColumns,
  filters
) => {
  const filter = filters.filter((f) => f.context === context)[0];
  const filtered = filterSubmissions(
    submissions,
    searchInput,
    searchableColumns,
    filters.filter((item) => item.context !== context)
  );

  const LAYER_FILTERS = { status: 1 };

  const getFilterValues = () => {
    if (filter.name in LAYER_FILTERS) {
      return [
        ...new Set(
          filtered.flatMap((s) => s.layers.map((l) => l[filter.name]))
        ),
      ];
    } else {
      return [...new Set(filtered.map((x) => x[filter.name] ?? ""))];
    }
  };
  return getFilterValues().map((v) => ({ value: v, label: v ? v : "[Blank]" }));
};

const makeLeadingColsStyle = ({ submission, index }) =>
  index === submission.layers.length - 1 ? {} : { borderBottom: "none" };

export class ColDef {
  static stringCmp = (key, submissionLevel) => {
    if (submissionLevel) return compareStringFields((s) => s[key]);
    else return compareStringFields((s) => s.layers?.[0]?.[key]);
  };
  static numberCmp = (key, submissionLevel) => {
    if (submissionLevel) return compareNumericFields((s) => s[key] ?? 0);
    else return compareNumericFields((s) => s.layers?.[0]?.[key] ?? 0);
  };
  static mkCell = (key, submissionLevel, numeric) => {
    if (submissionLevel) {
      if (numeric) {
        return ({ submission, layer, index }) => (
          <TableCell
            className={"tablecell-inception"}
            sx={makeLeadingColsStyle({ submission, index })}
          >
            {index === 0 ? (
              <NumberFormat
                value={submission[key]}
                displayType={"text"}
                thousandSeparator={true}
                defaultValue={""}
                decimalScale={0}
                className={`monospace`}
              />
            ) : null}
          </TableCell>
        );
      } else if (key === "insured") {
        return ({ submission, layer, index }) => (
          <TableCell
            className={"tablecell-insured"}
            sx={makeLeadingColsStyle({ submission, index })}
          >
            {index === 0 && (
              <Link
                to={`/submissions/${submission.submissionId}`}
                target={"_blank"}
                style={{ color: "#ff5c00" }}
              >
                {submission[key]}
              </Link>
            )}
          </TableCell>
        );
      } else {
        return ({ submission, layer, index }) => (
          <TableCell
            className={"tablecell-inception"}
            sx={makeLeadingColsStyle({ submission, index })}
          >
            {index === 0 && submission[key]}
          </TableCell>
        );
      }
    } else {
      if (numeric) {
        return ({ submission, layer, index }) => (
          <GoodBadTableCell value={layer?.[key]} />
        );
      } else {
        return ({ submission, layer, index }) => (
          <TableCell>{layer?.[key]}</TableCell>
        );
      }
    }
  };

  constructor(key, title = null) {
    if (!title)
      title = key
        .replaceAll(/([a-z])([A-Z])/g, "$1 $2")
        .replaceAll(/[_-]/g, " ")
        .toLowerCase()
        .replaceAll(/(^|\s)\S/g, (mtch) => mtch.toUpperCase());
    const submissionLevel = key in SUBMISSION_LEVEL_COLUMN_NAMES;
    this.key = key;
    this.title = title;
    this.numeric = !(key in TEXT_COLUMNS);
    this.compare = (this.numeric ? ColDef.numberCmp : ColDef.stringCmp)(
      this.key
    );
    this.splitLayersOnSort = !submissionLevel;
    this.isSearchable = key in TEXT_COLUMNS;
    this.Cell = (key in SPECIAL_COLUMN_FORMATTING
      ? SPECIAL_COLUMN_FORMATTING[key]
      : ColDef.mkCell)(key, submissionLevel, this.numeric);
  }
}

const RequestIndicator = ({ loading, error }) => {
  if (loading == null && !error) {
    return <></>;
  }
  return (
    <Tooltip
      title={
        error
          ? "Failed to save changes to Marmalade"
          : loading
          ? "Uploading changes to Marmalade"
          : "Succesfully uploaded changes to Marmalade"
      }
    >
      {error ? (
        <FailedIcon style={{ color: "crimson", top: "8px" }} />
      ) : loading ? (
        <div>
          <DefaultLoader
            color={"#dc7f4c"}
            style={{
              top: "8px",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            size={14}
          />
        </div>
      ) : (
        <SuccessIcon style={{ color: "green", top: "8px" }} />
      )}
    </Tooltip>
  );
};

const SPECIAL_COLUMN_FORMATTING = {
  status: (key, submissionLevel, numeric) => ({
    submission,
    layer,
    index,
    editing,
  }) => {
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(null);
    const [
      patchSubmission,
      { isError, isLoading },
    ] = submissionsService.usePatchSubmissionMutation();

    useEffect(() => {
      if (!isLoading) {
        setLoading((loading) => (loading ? false : null));
        setTimeout(() => {
          setLoading(null);
        }, 3000);
      }
    }, [isLoading]);

    useEffect(() => {
      if (isError) {
        setValue(null);
      }
    }, [isError]);

    if (editing) {
      return (
        <TableCell align={"left"}>
          <Stack direction={"row"} spacing={1}>
            <div
              style={
                isError
                  ? {
                      border: "1px solid red",
                      display: "inline-block",
                      borderRadius: 5,
                    }
                  : {}
              }
            >
              <StatusSelectV2
                status={isError ? layer[key] : value || layer[key]}
                disabled={loading}
                variant={"compact"}
                onChange={(e) => {
                  patchSubmission({
                    submissionId: submission.submissionId,
                    path: `state.pricing.layers[${index}].status`,
                    type: "REPLACE",
                    value: e,
                  });
                  setValue(e);
                  setLoading(true);
                }}
              />
            </div>
            <RequestIndicator loading={loading} error={isError} />
          </Stack>
        </TableCell>
      );
    } else {
      return (
        <TableCell align={"center"}>
          <StatusBadgeV2 status={value || layer[key]} />
        </TableCell>
      );
    }
  },
  targetPrice: (key, submissionLevel, numeric) => ({
    submission,
    layer,
    index,
  }) => (
    <GoodBadTableCell
      good={(layer[key] ?? 100) - 100}
      value={layer[key]}
      suffix={"%"}
      decimalScale={2}
      fixedDecimalScale
    />
  ),

  isPuniWrapIncluded: (key, submissionLevel, numeric) => ({
    submission,
    layer,
    index,
  }) => (
    <TableCell align={"center"}>
      <Checkbox checked={layer[key]} />
    </TableCell>
  ),
};

const SUBMISSION_LEVEL_COLUMN_NAMES = {
  inception: true,
  expiration: true,
  insured: true,
  reference: true,
  industryClass: true,
  dateReceived: true,
  quoteDeadline: true,
  quoteDate: true,
  preferredUnderwriter: true,
  secondaryUnderwriter: true,
  brokerHouse: true,
  broker: true,
  tiv: true,
};

const TEXT_COLUMNS = {
  submissionId: true,
  inception: true,
  expiration: true,
  insured: true,
  reference: true,
  preferredUnderwriter: true,
  secondaryUnderwriter: true,
  broker: true,
  brokerHouse: true,
  industryClass: true,
  dateReceived: true,
  quoteDeadline: true,
  quoteDate: true,
  reviewer: true,
  reviewDate: true,
  reviewDone: true,
  reviewComplete: true,
  status: true,
  bound: true,
  layerReference: true,
  policyType: true,
  policyForm: true,
  paperProvider: true,
  businessUnit: true,
  comment: true,
  puniReference: true,
};

const DEFAULT_COLUMN_LIST = [
  { key: "inception" },
  { key: "expiration" },
  { key: "insured" },
  { key: "industryClass" },
  { key: "preferredUnderwriter", title: "Underwriter" },
  { key: "brokerHouse", title: "Broking House" },
  { key: "broker" },
  { key: "status" },
  { key: "premium" },
  { key: "brokeragePremium", title: "Brokerage" },
  { key: "limit" },
  { key: "attachment" },
  { key: "layerReference", title: "Reference" },
  { key: "targetPrice" },
];

export const FILTER_FIELDS = [
  "status",
  "insured",
  "industryClass",
  "paperProvider",
  "policyForm",
  "businessUnit",
  "preferredUnderwriter",
  "brokerHouse",
  "broker",
];

export const createColumns = (colConfig) => {
  const keys = {};
  const list = (colConfig?.reviewColumns || []).length
    ? colConfig.reviewColumns
    : DEFAULT_COLUMN_LIST;
  return list
    .filter((spec) => {
      if (spec.key in keys) return false;
      keys[spec.key] = true;
      return true;
    })
    .map((spec) => new ColDef(spec.key, spec.title ?? null));
};

export const mkFilterFieldList = ({ columns, searchParams }) => {
  const columnTitles = Object.fromEntries(
    (columns ?? []).map(({ key, title }) => [key, title])
  );
  return FILTER_FIELDS.filter((key) => key in columnTitles).map((key) => ({
    name: key,
    context: columnTitles[key],
    value: (searchParams?.get(`${key}__in`)?.split(",") ?? []).map(
      decodeURIComponent
    ),
    default: [],
  }));
};

export const LocalLoader = () => (
  <DefaultLoader
    color={"#dc7f4c"}
    style={{
      width: "200px",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
    }}
    size={60}
  />
);
