import BrokerCreationTemplate from "./BrokerCreationTemplate";
import PersonSelect from "./PersonSelect";
import Disabled from "components/common/Disabled";
import { useSelector, useDispatch } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as peopleActions from "store/actions/people/peopleActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import * as peopleSelectors from "store/selectors/people/peopleSelectors";

const RetailBrokerCreation = ({ onCreate, onClose }) => {
  const { data: config } = staticDataService.useConfigQuery("retail_brokers");
  const brokers = config?.all ?? [];

  const [createBroker] = staticDataService.useCreateRetailBrokerMutation();

  return (
    <BrokerCreationTemplate
      brokers={brokers}
      createBroker={createBroker}
      visibilityBaseTag={"<BrokerCreation>"}
      formId={"retail-broker-creation-form"}
      title={"Add a new retail broker"}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
};

const RetailBroker = () => {
  const {
    data: brokers,
    refetch: refreshBrokers,
  } = staticDataService.useConfigQuery("retail_brokers");

  const dispatch = useDispatch();

  const broker = useSelector(peopleSelectors.selectRetailBroker);

  const updateBroker = (value) => {
    dispatch(peopleActions.updateRetailBroker(value));
  };

  const sortedBrokers = [...(brokers?.all ?? [])].sort(
    (a, b) =>
      (a.company || "z").localeCompare(b.company || "z") ||
      (a.name || "z").localeCompare(b.name || "z")
  );

  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  return (
    <Disabled ifReadOnly>
      <PersonSelect
        person={broker}
        personList={sortedBrokers}
        onUpdatePerson={updateBroker}
        onUpdatePersonList={refreshBrokers}
        PersonCreationComponent={RetailBrokerCreation}
        label={translateLabel("RETAIL_BROKER", {
          default: "Retail Broker",
        })}
        showLabelOnCard
      />
    </Disabled>
  );
};

export default RetailBroker;
