import { Box, Typography } from "@mui/material";
import * as strings from "common/strings";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import FilterBar from "components/common/FilterBar";
import FilterSelect from "components/common/FilterSelect";
import ErrorDialog from "components/error/ErrorDialog";
import Dashboard from "components/layout/Dashboard";
import DashboardContent from "components/layout/DashboardContent";
import DetailsByInsured from "components/scenarios/DetailsByInsured";
import DetailsByScenario from "components/scenarios/DetailsByScenario";
import FilterBarOptions from "components/scenarios/FilterBarOptions";
import ScenarioMetrics from "components/scenarios/ScenarioMetrics";
import * as support from "components/scenarios/ScenariosAppSupport";
import Summary from "components/scenarios/Summary";
import { GROUPING_MODE } from "components/scenarios/constants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as reportService from "services/reportService";
import * as staticDataActions from "store/actions/temp/staticData/staticDataActions";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const Loading = () => (
  <Component>
    <DefaultLoader
      color={"#dc7f4c"}
      style={{
        width: "200px",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      size={60}
    />
  </Component>
);

const NoMatches = () => (
  <Typography
    variant={"h6"}
    align={"center"}
    paddingTop={3}
    paddingBottom={2}
    color={"dimgray"}
  >
    {"No matches found"}
  </Typography>
);

const ScenariosApp = ({
  retrieveScenarioConfig,
  retrieveLayerStatusLabels,
  scenarioConfig,
}) => {
  useEffect(() => {
    retrieveScenarioConfig(["scenarios"]);
    retrieveLayerStatusLabels();
  }, [retrieveScenarioConfig, retrieveLayerStatusLabels]);

  const {
    data: scenariosReport,
    isLoading,
    status: scenarioStatus,
    error,
  } = reportService.useReportQuery({
    type: "scenario_list",
    args: {},
    format: "json",
    filename: "scenario_list",
  });

  const isRejected = scenarioStatus === "rejected";
  const scenarios = scenariosReport?.scenarios ?? [];

  const [inForceDate, setInforceDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [searchInput, setSearchInput] = useState(null);
  const [grouping, setGrouping] = useState(GROUPING_MODE.SUMMARY);
  const [sortByInsured, setSortByInsured] = useState(false);
  const scenarioLabels = support.getScenarioLabels(scenarioConfig);
  const scenarioCategories = support.getScenarioCategories(scenarioConfig);
  const initialFilters = [
    {
      context: "Status",
      value: ["BOUND"],
      default: ["BOUND"],
    },
    {
      context: "Severity",
      value: [],
      default: [],
    },
    {
      context: "Scenario",
      value: [],
      default: [],
    },
    {
      context: "Insured",
      value: [],
      default: [],
    },
    {
      context: "Industry",
      value: [],
      default: [],
      formatter: (x) => strings.toSentenceCase(x),
    },
  ];
  const [filters, setFilters] = useState(initialFilters);

  const searchTerm = (searchInput || "").trim().toLowerCase();
  const filteredScenarios = support.filterScenarios(
    scenarios,
    scenarioLabels,
    searchTerm,
    inForceDate,
    filters
  );
  const filtersHaveNoMatches =
    filteredScenarios.length === 0 &&
    filters.some((item) => item.value.join() !== item.default.join());

  const filterDialogDefault = {
    open: false,
    context: null,
    items: [],
    sortItems: true,
    initialSelected: [],
  };
  const [filterDialog, setFilterDialog] = useState(filterDialogDefault);

  const openFilterDialog = (context) => {
    setFilterDialog({
      ...filterDialog,
      open: true,
      context: context,
      items: support.getFilterItems(
        context,
        scenarios,
        scenarioLabels,
        searchTerm,
        inForceDate,
        filters
      ),
      sortItems: context !== "Severity",
      selected: filters.filter((x) => x.context === context)[0].value,
    });
  };

  const closeFilterDialog = () => {
    setFilterDialog(filterDialogDefault);
  };

  useEffect(() => {
    if (Object.keys(scenarioLabels).length > 0) {
      const updated = [...filters];
      const item = updated.find((x) => x.context === "Scenario");
      if (item.formatter == null) {
        item.formatter = (x) => scenarioLabels[x] ?? strings.toSentenceCase(x);
        setFilters(updated);
      }
    }
  }, [scenarioLabels, filters]);

  const updateFilter = (context, value) => {
    const updated = filters.map((item) => {
      return {
        ...item,
        value: item.context === context ? value : item.value,
      };
    });
    setFilters(updated);
  };

  return (
    <div className={"app"}>
      <ErrorDialog />
      <Dashboard>
        <DashboardContent isFullWidth={true}>
          {isRejected ? (
            error
          ) : isLoading ? (
            <Loading />
          ) : (
            <>
              <Component>
                <FilterBar
                  filters={filters}
                  onChangeSearchInput={(searchText) =>
                    setSearchInput(searchText)
                  }
                  onChangeFilters={(value) => setFilters(value)}
                  onClickFilter={(context) => openFilterDialog(context)}
                  options={
                    <FilterBarOptions
                      date={inForceDate}
                      grouping={grouping}
                      sortByInsured={sortByInsured}
                      onChangeDate={(date) => setInforceDate(date)}
                      onChangeGrouping={(mode) => setGrouping(mode)}
                      onChangeSort={(sortByInsured) =>
                        setSortByInsured(sortByInsured)
                      }
                    />
                  }
                />

                <FilterSelect
                  context={filterDialog.context}
                  items={filterDialog.items}
                  sortItems={filterDialog.sortItems}
                  initialSelected={filterDialog.selected}
                  open={filterDialog.open}
                  onClose={closeFilterDialog}
                  onChange={updateFilter}
                />

                {grouping !== GROUPING_MODE.SUMMARY && (
                  <Box
                    sx={{
                      margin: "0.5rem 0",
                      padding: "0.875rem 0.375rem",
                      background: "#edf0f3",
                      borderRadius: "0.625rem",
                    }}
                  >
                    <ScenarioMetrics
                      scenarios={filteredScenarios}
                      categories={scenarioCategories}
                    />
                  </Box>
                )}

                {grouping === GROUPING_MODE.BY_SCENARIO && (
                  <DetailsByScenario
                    scenarios={filteredScenarios}
                    scenarioLabels={scenarioLabels}
                  />
                )}

                {grouping === GROUPING_MODE.BY_INSURED && (
                  <DetailsByInsured
                    scenarios={filteredScenarios}
                    scenarioLabels={scenarioLabels}
                    sortByInsured={sortByInsured}
                  />
                )}

                {grouping === GROUPING_MODE.SUMMARY && (
                  <Summary
                    scenarios={filteredScenarios}
                    scenarioLabels={scenarioLabels}
                    scenarioCategories={scenarioCategories}
                  />
                )}

                {filtersHaveNoMatches && <NoMatches />}
              </Component>
            </>
          )}
        </DashboardContent>
      </Dashboard>
    </div>
  );
};

const mapStateToProps = (state) => ({
  scenarioConfig: staticDataSelectors.selectConfig("scenarios")(state),
});

const mapDispatchToProps = {
  retrieveScenarioConfig: staticDataActions.retrieveConfig.requested,
  retrieveLayerStatusLabels:
    staticDataActions.retrieveLayerStatusLabels.requested,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosApp);
